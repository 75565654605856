<template>
  <div class="additem">
    <Pagenationtopimg />
    <Pagenationtop :pagenation_title="pagenation_title" path="AddItem/this.$route.params.mainid" />
    <div class="additem-container">
        <div class="content" v-html="main_data.content">
        </div>
        <div class="date">
            {{main_data.date}}
        </div>
    </div>
  </div>
</template>

<script>
import Pagenationtopimg from "../components/pagenationimg/index.vue";
import Pagenationtop from "../components/pagenationtop/index.vue";
import qs from 'qs'
export default {
    data(){
        return{
            pagenation_title:[],
            main_data:null
        }
    },

    mounted(){
        this.$store.commit('closeOverlay')
        this.getCustomPage()
    },
    components: {
        Pagenationtopimg,
        Pagenationtop,
    },
    methods:{
        getCustomPage(){
            this.axios.post('/api/custompage/getcustompage',qs.stringify({
                lang: this.$store.state.lang,
                mainid: this.$route.params.mainid
            }))
            .then(response => (
                console.log(response),
                this.pagenation_title.push(response.data.result.title),
                this.main_data = response.data.result
            ))
        }
    }
}
</script>

<style>

</style>