<template>
    <div class="shoppingcenter">
        <Pagenationtopimg />
        <Pagenationtop :pagenation_title="pagenation_title" path="ShoppingCenter" />
        <fixedshop />
        <div class="shopping-container">
            <div class="left"
            :class="{active:openfilter}">
                <template v-if="!skeleton_loader">
                    <div class="close-container">
                        <v-icon
                        @click="closeFilter()">
                            mdi-close-circle-outline
                        </v-icon>
                    </div>
                    <v-text-field label="search..." v-model="search"
                    @keyup.enter="getSearchProduct(search)">
                        <v-icon
                        slot="append"
                        color=""
                        >
                            mdi-card-search-outline
                        </v-icon>
                    </v-text-field>
                        <div class="title-container">
                            <template v-if="product_content != null">
                                <p class="ps-title">
                                    {{product_sub_content[0].title}}
                                    <span v-if="this.$route.params.iid === undefined && activeIndex != -1">{{product_sub_sub_title}}</span>
                                    <span v-if="this.$route.params.iid && activeIndex != -1">-{{product_sub_sub_content[0].title}}</span>
                                    <template v-if="total_product">
                                        ({{ total_product }})
                                    </template>
                                    <template v-else>
                                        (0)
                                    </template>
                                </p>
                                <p class="content-tx"
                                @click="getDataAll()"
                                :class="{active: activeIndex === -1}">
                                    {{product_sub_content[0].title}}
                                </p>
                                <p class="content-tx" 
                                v-for="(item,i) in product_sub_content[0].data"
                                :key="item.index"
                                :class="{active: activeIndex === i}"
                                @click="activeTo(i,item.mainid)">
                                    {{ item.title }}
                                </p>
                            </template>
                        </div>
                        <div class="select-container">
                            <p class="ps-title">
                                選擇
                            </p>
                            <div class="button">
                                <v-btn
                                @click="getProductSeries(i,item.mainid)"
                                v-for="(item,i) in get_products_series"
                                :class="{'active':serise_index === i}"
                                :key="item.index">
                                    {{item.title}}
                                </v-btn>
                            </div>
                        </div>
                        <div class="color-container">
                            <p class="ps-title">
                                顏色
                            </p>
                            <v-row
                            >
                                <v-col
                                cols="6"
                                lg="4"
                                v-for="(item,index) in color_data"
                                @click="colorTo(index,item.mainid), closeFilter"
                                :key="item.index">
                                    <div class="canvas"
                                    :class="{active: activeColor === index }"
                                    :style="item.color_code"
                                    >
                                        <div class="border"
                                        :class="{active: activeColor === index}">
                                        </div>
                                    </div>
                                    <p>
                                        {{item.title}}
                                    </p>
                                </v-col>
                            </v-row>
                        </div>

                        <div class="size-container">
                            <div class="ps-title">
                                尺寸
                            </div>
                            <v-btn v-for="(item,i) in size_data"
                            :key="item.index" 
                            @click="getSizeProduct(i,item.mainid)"
                            :class="{'active':size_index == i}">{{ item.title }}</v-btn>
                        </div>
                </template>        
                <template v-else>
                    <v-skeleton-loader
                    max-width="300"
                    type="article">
                    </v-skeleton-loader>
                    <v-skeleton-loader
                    max-width="300"
                    type="article">
                    </v-skeleton-loader>
                    <v-skeleton-loader
                    max-width="300"
                    type="article">
                    </v-skeleton-loader>
                    <v-skeleton-loader
                    max-width="300"
                    type="article">
                    </v-skeleton-loader>
                </template>
            </div>
        
            <div class="right">
                    <div class="title-container">
                        <div class="title-content">
                            <p class="ps-title"
                            v-if="product_sub_content">
                                {{product_sub_content[0].title}}
                                <span v-if="this.$route.params.iid === undefined">{{product_sub_sub_title}}</span>
                                <span v-else>-{{product_sub_sub_content[0].title}}</span>
                                <template v-if="total_product">
                                    ({{ total_product }})
                                </template>
                                <template v-else>
                                    (0)
                                </template>
                            </p>
                            <v-btn
                            @click="openFilter()">
                                篩選
                                <v-icon>
                                    mdi-tune-variant
                                </v-icon>
                            </v-btn>
                        </div>
                    </div>
                <v-row>
                    <v-slide-x-reverse-transition group class="transition">
                        <template v-if="skeleton_loader === false">
                            <v-col
                            cols="6"
                            md="4"
                            v-for="(item,item_index) in product_data"
                            @click="router(item.mainid)"
                            :key="item_index">
                                <template >
                                    <div class="img-container">
                                        <div class="self-container"
                                        v-if="item.self != null">
                                            {{item.self}}
                                        </div>
                                        <img class="main-img" :src='"/api/"+item.img' alt="">
                                        <div class="icon-container">
                                            <div class="icon-sub-container"
                                            @click.stop="addInsertCollect(item.id)">
                                                <img class="love" src="../../../public/img/product-love.png" alt="">
                                            </div>
                                            <div class="icon-sub-container">
                                                <img class="love" src="../../../public/img/product-shop.png" alt="">
                                            </div>
                                        </div>
                                    </div>
                                    <p class="tx-red ps-sub-tx"
                                    v-if="item.isnew == '1'">
                                        新品上市
                                    </p>
                                    <p class="flex-1">{{item.title}}</p>
                                    <div class="content" v-if="item.summary">
                                        <p>{{item.summary}}</p>
                                    </div>
                                    <p class="price-tx">${{item.price}}
                                    </p>
                                </template>
                            </v-col>
                        </template>
                        <template v-else>
                            <v-col
                            :key="i"
                            cols="6"
                            sm="4"
                            v-for="(item,i) in 10">
                                <v-skeleton-loader
                                max-width="300"
                                type="card">
                                </v-skeleton-loader>
                            </v-col>
                        </template>
                    </v-slide-x-reverse-transition>
                </v-row>
            <template v-if="activeIndex != null">
                <div class="text-center">
                    <v-pagination
                    v-model="page"
                    :length="totalpage"
                    :total-visible="7"
                    circle
                    color=grey
                    @input="next"
                    ></v-pagination>
                </div>
            </template>
            <template v-else>
                <div class="text-center 123">
                    <v-pagination
                    v-model="page"
                    :length="totalpage"
                    :total-visible="7"
                    circle
                    color=grey
                    @input="next"
                    ></v-pagination>
                </div>
            </template>

            </div>
        </div>
    </div>
</template>

<script>
import Pagenationtopimg from "../../components/pagenationimg/index.vue";
import Pagenationtop from "../../components/pagenationtop/index.vue";
import fixedshop from '../../components/fixedshop/index.vue'
import qs from 'qs'
export default {
    data(){
        return{
            skeleton_loader:true,
            size_index:null ,
            size_data:null,
            search:null,
            serise_index:null,
            totalpage:1,
            page:1,
            total_product:null,
            activeIndex:null,
            activeColor:-1,
            info: null,
            openfilter:false,
            loading:false,
            styleObject: {
                backgroundColor: 'red',
                borderWidth: '5px',
            },
            pagenation_title:[],
            product_sub_sub_title:null,
            product_sub_sub_content:{
                title:null
            },
            product_sub_content:null,
            product_content:null,
            product_data:null,
            get_products_series:null,
            color_data:null,
        }
    },
    created(){        
        this.$store.commit('openOverlay')
        this.getSizeData()
        this.getSeries()
        this.getData()
        this.getSortTitle()
        console.log('-------------------------')
        this.getColor()
    },
    mounted(){

    },
    components: {
        Pagenationtopimg,
        Pagenationtop,
        fixedshop
    },

    methods:{
        //分頁下一頁
        next(page){
            this.$store.commit('openOverlay')
            this.axios.post('/api/products/get_products_list', qs.stringify({
                lang: this.$store.state.lang,
                fid: this.$route.params.fid,
                sid: this.$route.params.sid,
                p_item: this.$route.params.iid,
                page: page,
                search: this.$route.params.search,
                isdealer: this.$session.get('isdealer')
            }))
            .then(response => (
                console.log(response),
                this.product_data = response.data.result,
                this.totalpage = response.data.totalpage,
                this.total_product = response.data.recordcount,
                this.$store.commit('closeOverlay')
            ))
        },
        //系列api
        getSeries(){
            this.axios.post('/api/products/get_products_series',qs.stringify({
                lang: this.$store.state.lang,
                fid: this.$route.params.fid,
                sid: this.$route.params.sid,
            }))
            .then(responese => (
                this.get_products_series = responese.data.result
            ))
        },
        //加入收藏
        addInsertCollect(id){
            this.$store.commit('openOverlay')
            if(this.$session.get('memberLogin')){
                this.axios.post('/api/products/insert_collect', qs.stringify({
                    lang: this.$store.state.lang,
                    p_id: id,
                    m_id: this.$session.get('memberId'),
                    ismain: 1
                }))
                .then(response => (
                    console.log(response),
                    alert(response.data.msg),
                    this.$store.commit('closeOverlay')
                ))
            }
            else{
                alert('請先登入會員')
                this.$store.commit('activeLogin')
                this.$store.commit('closeOverlay')
            }
        },
        openFilter(){
            this.openfilter = true
            document.querySelector('body').setAttribute('style', 'overflow:hidden;height:100vh' ,)
        },
        closeFilter(){
            this.openfilter = false
            document.querySelector('body').setAttribute('style', 'overflow:visible;height:auto')
        },
        router(mainid){
            this.$router.push({name:`Productview` , 
            params: {mainid:mainid }})
        },

        ///api

        //產品列表
        getData(){
            this.activeIndex = -1
            if(this.$route.params.iid){
                this.axios.post('/api/products/get_products_list', qs.stringify({
                    lang: this.$store.state.lang,
                    fid: this.$route.params.fid,
                    sid: this.$route.params.sid,
                    p_item: this.$route.params.iid,
                    isdealer: this.$session.get('isdealer')
                }))
                .then(responese => (
                    console.log("basice_data"),
                    console.log(responese),
                    this.product_data = responese.data.result,
                    this.totalpage = responese.data.totalpage,
                    this.total_product = responese.data.recordcount,
                    this.skeleton_loader = false
                ))
            }
            else if(this.$route.params.search){
                console.log('search')
                this.axios.post('/api/products/get_products_list',qs.stringify({
                    lang: this.$store.state.lang,
                    fid: this.$route.params.fid,
                    sid: this.$route.params.sid, 
                    search: this.$route.params.search,
                    isdealer: this.$session.get('isdealer')
                }))
                .then(response => (
                    this.product_sub_sub_title=null,
                    console.log(response),
                    this.product_data = response.data.result,
                    this.totalpage = response.data.totalpage,
                    this.total_product = response.data.recordcount,
                    this.search = '',
                    this.skeleton_loader = false
                ))
            }
            else{
                this.axios.post('/api/products/get_products_list', qs.stringify({
                    lang: this.$store.state.lang,
                    fid: this.$route.params.fid,
                    sid: this.$route.params.sid,
                    isdealer: this.$session.get('isdealer')
                }))
                .then(responese => (
                    console.log(123),
                    console.log(responese),
                    this.product_data = responese.data.result,
                    this.totalpage = responese.data.totalpage,
                    this.total_product = responese.data.recordcount,
                    this.skeleton_loader = false
                ))
            }
        },

        getDataAll(){
            this.$store.commit('openOverlay')
            this.activeIndex = -1
            this.axios.post('/api/products/get_products_list', qs.stringify({
                lang: this.$store.state.lang,
                fid: this.$route.params.fid,
                sid: this.$route.params.sid,
                isdealer: this.$session.get('isdealer')
            }))
            .then(responese => (
                console.log(123),
                console.log(responese),
                this.product_data = responese.data.result,
                this.totalpage = responese.data.totalpage,
                this.total_product = responese.data.recordcount,
                this.skeleton_loader = false,
                this.$store.commit('closeOverlay')
            ))
        },

        getColor(){
            this.axios.post('/api/products/get_products_color',qs.stringify({
                lang: this.$store.state.lang,
                fid: this.$route.params.fid,
                sid: this.$route.params.sid,
            }))
            .then( res => (
                console.log('---------顏色-----------'),
                console.log(res),
                this.color_data = res.data.result
            ))
        },

        //產品分類
        getSortTitle(){
            let vm = this
            vm.axios.post('/api/products/get_fkind', qs.stringify({
                lang: vm.$store.state.lang,
            }))
            .then(responese => (
                console.log('get_fkind'),
                console.log(responese),
                vm.product_content = responese.data.result,
                vm.product_content = vm.product_content.filter(function(res) {
                    return res.mainid === vm.$route.params.fid
                }),
                this.pagenation_title.push(vm.product_content[0].title),
                vm.product_sub_content = vm.product_content[0].data.filter(function(res) {
                    return res.mainid === vm.$route.params.sid
                }),
                this.pagenation_title.push(vm.product_sub_content[0].title),
                this.$store.commit('closeOverlay'),
                vm.filterIid()
            ))
        },
        filterIid(){
            let vm = this
            if(this.$route.params.iid === undefined){
                console.log('')
            }
            else{
                let data = vm.product_sub_content[0].data.filter(function(res) {
                    return res.mainid === vm.$route.params.iid
                })
                console.log(data)
                let data_index = null
                vm.product_sub_content[0].data.forEach((element,index) => {
                        if(element.mainid === data[0].mainid){
                            data_index = index
                        }
                });
                console.log('--------------data-----------')
                vm.product_sub_sub_content = data
                vm.activeIndex = data_index
                console.log('--------------------------')
                console.log(vm.product_sub_sub_content)
            }
        },
        //顏色篩選
        colorTo(index,p_color){
            this.activeColor = index
            let p_size = null
            if(this.size_index != -1 && this.size_index != null){
                p_size = this.size_data[this.size_index].mainid
            }
            let p_series = null
            if(this.serise_index != -1 && this.serise_index != null){
                p_series = this.get_products_series[this.serise_index].mainid
            }
            let p_item = null
            if(this.activeIndex != -1 && this.activeIndex != null){
                p_item = this.product_sub_content[0].data[this.activeIndex].mainid
            }
            console.log(p_item)
            this.$store.commit('openOverlay')
            this.closeFilter()
            this.axios.post('/api/products/get_products_list',qs.stringify({
                lang: this.$store.state.lang,
                fid: this.$route.params.fid,
                sid: this.$route.params.sid, 
                p_color: p_color,
                p_item: p_item,   
                p_series: p_series,
                p_size: p_size
            }))
            .then(response => (
                console.log(response),
                this.product_sub_sub_title=null,
                this.total_product = response.data.recordcount,
                this.totalpage = response.data.totalpage,
                this.product_data = response.data.result,
                this.$store.commit('closeOverlay')
            ))
        },
        //取尺寸
        getSizeData(){
            this.axios.post('/api/products/get_products_size',qs.stringify({
                lang: this.$store.state.lang,
                fid: this.$route.params.fid,
                sid: this.$route.params.sid, 
            }))
            .then(response => (
                console.log('---------尺寸-----------'),
                console.log(response),
                this.size_data = response.data.result,
                this.size_data.sort(function(a,b){
                    return a.orderid - b.orderid
                })
            ))
        },
        //產品尺寸搜尋
        getSizeProduct(index,p_size){
            this.size_index = index
            let p_color = null
            if(this.activeColor != -1 && this.activeColor != null){
                p_color = this.color_data[this.activeColor].mainid
            }
            let p_item = null
            if(this.activeIndex != -1 && this.activeIndex != null){
                p_item = this.product_sub_content[0].data[this.activeIndex].mainid
            }
            let p_series = null
            if(this.serise_index != -1 && this.serise_index != null){
                p_series = this.get_products_series[this.serise_index].mainid
            }
            this.$store.commit('openOverlay')
            this.closeFilter()
            this.axios.post('/api/products/get_products_list',qs.stringify({
                lang: this.$store.state.lang,
                fid: this.$route.params.fid,
                sid: this.$route.params.sid, 
                p_size: p_size,
                p_color: p_color,
                p_item: p_item,   
                p_series: p_series,
            }))
            .then(response => (
                this.product_sub_sub_title=null,
                this.total_product = response.data.recordcount,
                this.product_data = response.data.result,
                this.totalpage = response.data.totalpage,
                this.$store.commit('closeOverlay')
            ))
        },
        //文字搜尋
        // getSearchProduct(search){
        //     this.activeColor = null
        //     this.activeIndex = null
        //     this.size_index = null
        //     this.serise_index = null
        //     this.$store.commit('openOverlay')
        //     this.closeFilter()
        //     this.axios.post('/api/products/get_products_list',qs.stringify({
        //         lang: this.$store.state.lang,
        //         fid: this.$route.params.fid,
        //         sid: this.$route.params.sid, 
        //         search: search
        //     }))
        //     .then(response => (
        //         this.product_sub_sub_title=null,
        //         console.log(response),
        //         this.product_data = response.data.result,
        //         this.total_product = response.data.recordcount,
        //         this.$store.commit('closeOverlay'),
        //         this.search = ''
        //     ))
        // },
        //文字搜尋
        getSearchProduct(search){
            this.$router.push({name:`ShoppingCenter` , 
            params: {
                    fid: this.$route.params.fid,
                    sid: this.$route.params.sid, 
                    search: search
                } 
            })
        },
        //產品系列
        getProductSeries(index,p_series){
            this.serise_index = index
            let p_color = null
            if(this.activeColor != -1 && this.activeColor != null){
                p_color = this.color_data[this.activeColor].mainid
            }
            let p_item = null
            if(this.activeIndex != -1 && this.activeIndex != null){
                p_item = this.product_sub_content[0].data[this.activeIndex].mainid
            }
            let p_size = null
            if(this.size_index != -1 && this.size_index != null){
                p_size = this.size_data[this.size_index].mainid
            }
            this.$store.commit('openOverlay')
            this.closeFilter()
            this.axios.post('/api/products/get_products_list',qs.stringify({
                lang: this.$store.state.lang,
                fid: this.$route.params.fid,
                sid: this.$route.params.sid, 
                p_size: p_size,
                p_color: p_color,
                p_item: p_item,   
                p_series: p_series,
            }))
            .then(response => (
                console.log(response),
                this.product_sub_sub_title=null,
                this.total_product = response.data.recordcount,
                this.product_data = response.data.result,
                this.totalpage = response.data.totalpage,
                this.$store.commit('closeOverlay')
            ))
        },
        activeTo(index,p_item){
            this.activeColor = null
            this.activeIndex = null
            this.serise_index = null
            let vm = this
            vm.closeFilter()
            vm.$store.commit('openOverlay')
            vm.activeIndex = index
            vm.axios.post('/api/products/get_products_list',qs.stringify({
                lang: vm.$store.state.lang,
                fid: vm.$route.params.fid,
                sid: vm.$route.params.sid, 
                p_item: p_item
            }))
            .then(function(response){ 
                vm.$store.commit('closeOverlay')
                vm.product_data = response.data.result,
                vm.total_product = response.data.recordcount,
                vm.totalpage = response.data.totalpage
                if (vm.$route.params.iid != undefined){
                    vm.product_sub_sub_content[0].title = response.data.result[0].catalog
                }
                else if (vm.product_sub_sub_title){
                    vm.product_sub_sub_title = '-'+response.data.result[0].catalog
                }
            })
        },
    },
}
</script>

<style>

</style>