<template>
    <div class="productview" >
        <Pagenationtopimg />
        <Pagenationtop :pagenation_title="pagenation_title" 
        :shop="main_data"/>
        <fixedshop />
        <template v-if="main_data">
            <div class="carousel-container">
                <carousel :dots=false :items="carousel_item" :autoplay=true class="this-carousel dfn-min-991"
                v-if="main_data.img_list.small">
                    <div class="lg-img"
                    v-for="item in main_data.img_list.small"
                    :key="item.index">
                        <div class="img-container">
                            <img :src="'/api/'+item.pic" alt="">
                        </div>
                    </div>
                </carousel>
            </div>
            <div class="productview-container">
                <div class="left dfn-max-991"
                v-if="main_data.img_list.small">
                    <div class="lg-img"
                    @click.self="overlayImgStatus(i)"
                    v-for="(item,i) in main_data.img_list.small"
                    :key="item.index">
                        <img @click.self="overlayImgStatus(i)" :src="'/api/'+item.pic" alt="">
                        <template v-if="product_overlay == i">
                            <div class="overlay-container dfn-max-991">
                                <button
                                class="close"
                                @click="overlayImgStatus(i)">
                                    <i class="fas fa-times"></i>
                                </button>
                                <div class="overlay-lg-img">
                                    <img :src="'/api/'+main_data.img_list.big[i].pic" alt="">
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="right">
                    <div class="top-container">
                        <div class="ps-ab">
                            <p>{{ main_data.annotation}} </p>
                            <p v-if="main_data.price.self">${{ main_data.price.self}}</p>
                        </div>
                        <p class="ps-title">{{ main_data.title }}</p>
                        <p class="price">
                            <span>${{ main_data.price }}</span>
                        </p>

                    </div>
                    <p class="sub-title">
                        選擇顏色： <span class="black--text fsz-20">{{ check_color }}</span>
                    </p>
                    <div class="color-container">
                        <div class="img-container"
                        v-for="(item,item_index) in main_color"
                        :key="item.index"
                        @click="colorSelect(item_index,item.color_mainid,item.color_caption,item.id)"
                        :class="{active: color === item_index}">
                            <img :src="'/api/'+item.img" alt="">
                        </div>
                    </div>
                    <div class="size-title">
                        <p class="sub-title">
                            選擇尺寸：
                        </p>
                        <a href="/Size">
                            尺寸表
                        </a>
                    </div>
                    <div class="size-container">
                        <v-slide-x-reverse-transition group>
                            <template v-for="(item,item_index) in this.main_size">
                            <v-btn
                            :key="item_index"
                            @click="sizeSelect(item_index,item.size_mainid)"
                            :class="{active: size === item_index}"
                            v-if="item.stock > 0">
                                {{ item.size_caption }}
                            </v-btn>
                            <v-btn
                            :key="item_index"
                            disabled
                            v-else>
                                缺貨{{ item.size_caption }}
                            </v-btn>
                            </template>
                        </v-slide-x-reverse-transition>
                    </div>
                    <template v-if="color != null & size != null">
                        <div class="button-container">
                            <v-btn
                            @click="addShopCar">
                                <span>
                                    <img src="../../../public/img/nav_icon-3.png" alt="">
                                    加入購物車
                                </span>
                            </v-btn>
                            <v-btn
                            @click="addInsertCollect">
                                <span>
                                    <img src="../../../public/img/nav_icon-2.png" alt="">
                                    最愛
                                </span>
                            </v-btn>
                            <div v-if="main_data.onsale" class="red--text mt-2">{{main_data.onsale}}</div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="button-container">
                            <v-btn
                            disabled>
                                <span>
                                    <img src="../../../public/img/nav_icon-3.png" alt="">
                                    加入購物車
                                </span>
                            </v-btn>
                            <v-btn disabled>
                                <span>
                                    <img src="../../../public/img/nav_icon-2.png" alt="">
                                    最愛
                                </span>
                            </v-btn>
                            <div v-if="main_data.onsale" class="red--text mt-2">{{main_data.onsale}}</div>
                        </div>
                    </template>

                    <div class="product-content"
                    v-html="main_data.content">

                    </div>
                </div>
            </div>
            <div class="product-section">
                <div class="top">
                    <v-icon>
                        mdi-play-circle
                    </v-icon>
                    <span>產品其他內容</span>
                </div>
                <div class="product-section-html mt-10"
                v-html="main_data.other_content">
                </div>
            </div>
            <template v-if="main_data.videourl">
                <div class="youtube-container">
                    <iframe  :src="main_data.videourl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </template>
        </template>
        <!-- <template v-if="main_data">
            <div class="overlay-container dfn-max-991"
            v-if="product_overlay">
                <button
                class="close"
                @click="overlayImgStatus">
                    <i class="fas fa-times"></i>
                </button>
                <div class="overlay-lg-img"
                :id="i"
                v-for="(item,i) in main_data.img_list"
                :key="item.index">
                    <div class="img-container">
                        <img :src="'/api/'+item.pic" alt="">
                    </div>
                </div>
            </div>
        </template> -->
        <template v-if="products_card[0].p_list != null">
            <ProductCar ref="ProductCar" :products_card="products_card"/>
        </template>
    </div>
</template>

<script>
import carousel from 'vue-owl-carousel'
import Pagenationtopimg from "../../components/pagenationimg/index.vue";
import Pagenationtop from "../../components/pagenationtop/index.vue";
import ProductCar from '../../components/products-car/index.vue'
import qs from 'qs'
import fixedshop from '../../components/fixedshop/index.vue'

export default {
    data(){
        return{
            product_overlay:-1 , //產品大圖
            product_content:null ,  //大分類
            product_sub_content:null ,  //中分類
            test:null,
            pagenation_title:[],
            main_data:null,
            main_color:null,
            main_size:null,
            check_color:null,//目前顏色
            user_select:{
                c_mainid:null,
                s_mainid:null,
                id:null,
            },
            color:null,
            size:null,
            products_card:[
                {
                title:'熱門排行',
                p_list:null
                    // {
                    //     img:require('../../../public/img/bicycle-1.png'),
                    //     content:'E-PM235',
                    //     price:'$1,500',
                    //     description:'產品說明簡述最多只會顯示一行........',
                    // },
                },
            ],
            carousel_item:1,
        }
    },
    created(){
        this.$store.commit('openOverlay')
        let set = new Set()
        this.axios.post('/api/products/get_products_data', qs.stringify({
            lang: this.$store.state.lang,
            mainid: this.$route.params.mainid,
            isdealer: this.$session.get('isdealer'),
        }))
        .then(response => (
            console.log(response),
            this.main_data = response.data.result,
            // this.lg_img = this.main_data.img_list[0].pic,
            this.main_color = response.data.result.specification_products.filter(item => 
            !set.has(item.color_caption) ? set.add(item.color_caption) : false),
            this.products_card[0].p_list = response.data.result.other_products,
            this.colorSelect(
                0,
                this.main_color[0].color_mainid,
                this.main_color[0].color_caption,
                this.main_color[0].id),
            this.getSortTitle(
                response.data.result.fid,
                response.data.result.sid,
                response.data.result.iid),
            this.$store.commit('closeOverlay')
        ))
    },
    mounted(){

    },
    components: {
        Pagenationtopimg,
        Pagenationtop,
        ProductCar,
        fixedshop,
        carousel,
    },
    methods:{
        colorSelect(index,id,string,p_id){
            console.log(index+','+id+','+string+','+p_id)
            this.size = null
            this.color = index
            this.user_select.id = p_id
            this.user_select.c_mainid = id
            this.check_color = this.main_color[index].color_caption
            this.main_size = this.main_data.specification_products.filter(item => {
                return item.color_caption === string
            })
        },
        sizeSelect(index,id){
            this.size = index,
            this.user_select.s_mainid = id
        },
        addInsertCollect(){
            if(this.$session.get('memberLogin')){
                this.$store.commit('openOverlay')
                this.axios.post('/api/products/insert_collect', qs.stringify({
                    lang: this.$store.state.lang,
                    p_id: this.user_select.id,
                    m_id: this.$session.get('memberId'),
                    ismain: 0
                }))
                .then(response => (
                    console.log(response),
                    alert(response.data.msg),
                    this.$store.commit('closeOverlay')
                ))
                .catch(err => (
                    this.$store.commit('closeOverlay'),
                    alert(err)
                ))
            }
            else{
                alert('請先登入會員')
                this.$store.commit('activeLogin')
            }

        },
        addShopCar(){
            let vm = this
            if(this.$session.get('memberLogin')){
                this.$store.commit('openOverlay')
                this.axios.post('/api/car/add_car', qs.stringify({
                    lang: this.$store.state.lang,
                    m_id: this.$session.get('memberId'),
                    p_mainid: this.$route.params.mainid,
                    c_mainid:this.user_select.c_mainid,
                    s_mainid: this.user_select.s_mainid,
                    isdealer: this.$session.get('isdealer'),
                }))
                .then(function(response)  {
                    if(response.data.status){
                        vm.$session.set('shopcar',response.data.car),
                        vm.$store.commit('shopcarItem',response.data.car.item),
                        vm.$store.commit('closeOverlay')
                    }
                    else{
                        alert(response.data.msg),
                        vm.$store.commit('closeOverlay')
                    }
                })
                // .catch(err => (
                //     console.log(err),
                //     this.$store.commit('closeOverlay'),
                //     alert(err)
                // ))
            }
            else{
                alert('請先登入會員')
                this.$store.commit('activeLogin')
            }
        },
        //產品分類
        getSortTitle(fid,sid,iid){
            let vm = this
            vm.axios.post('/api/products/get_fkind', qs.stringify({
                lang: vm.$store.state.lang,
            }))
            .then(responese => (
                console.log(responese),
                vm.product_content = responese.data.result,
                vm.product_content = vm.product_content.filter(function(res) {
                    return res.mainid === fid
                }),
                vm.product_sub_content = vm.product_content[0].data.filter(function(res) {
                    return res.mainid === sid
                }),
                vm.product_sub_sub_content = vm.product_sub_content[0].data.filter(function(res) {
                    return res.mainid === iid
                }),
                vm.pagenation_title.push(vm.product_content[0].title),
                vm.pagenation_title.push(vm.product_sub_content[0].title),
                vm.pagenation_title.push(vm.product_sub_sub_content[0].title)
            ))
        },

        overlayImgStatus(index){
            if(this.product_overlay >= 0){
                this.$store.commit('bodyOverViseibel') 
                this.product_overlay = -1
            }
            else{
                this.$store.commit('bodyOverHidden')
                this.product_overlay = index
            }
        },
    }
}
</script>

<style>

</style>