<template>
    <div class="finalshopcar">
        <Pagenationtopimg />
        <Pagenationtop :pagenation_title="pagenation_title" path="" />
        <div class="finalshopcar-container">
            <div class="top-title">
                <p>親愛的
                    <span class="red--text">{{this.$session.get('memberName')}}</span>
                    您好，謝謝您的訂購，訂單編號為
                    <span class="red--text font-weight-bold">
                        {{this.$route.params.ordernumber}}
                    </span>
                </p>
            </div>
            <v-container
            fluid>
                <v-row>
                    <v-col
                    class="col-head"
                    cols="12">
                        訂購編號：{{this.$route.params.ordernumber}}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    class="col-title"
                    cols="">
                        {{checkout_data.title[0]}}
                    </v-col>
                    <v-col
                    class="col-content"
                    cols="8"
                    md="10">
                            {{checkout_data.content.createdate}}
                        <!-- <template 
                        v-if="checkout_data.content[i].type === 'button'">
                        <v-btn>
                            {{checkout_data.content[i].text}}
                        </v-btn>
                        </template>
                        <template 
                        v-if="checkout_data.content[i].type === 'red_tx'">
                            {{checkout_data.content[i].sub_text}}
                            <span class="red--text">{{checkout_data.content[i].text}}</span>
                        </template>
                        <template 
                        v-if="checkout_data.content[i].type === 'red_tx_tx'">
                            {{checkout_data.content[i].sub_text}}
                            <span class="red--text ps-fsz14">{{checkout_data.content[i].text}}</span>
                        </template>
                        <template 
                        v-if="checkout_data.content[i].type === 'text'">
                            <span>{{checkout_data.content[i].text}}</span>
                        </template> -->
                    </v-col>
                </v-row>    
                <v-row>
                    <v-col
                    class="col-title"
                    cols="">
                        {{checkout_data.title[1]}}
                    </v-col>
                    <v-col
                    class="col-content"
                    cols="8"
                    md="10">
                        {{checkout_data.content.get_name}}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    class="col-title"
                    cols="">
                        {{checkout_data.title[2]}}
                    </v-col>
                    <v-col
                    class="col-content"
                    cols="8"
                    md="10">
                        {{checkout_data.content.get_address}}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    class="col-title"
                    cols="">
                        {{checkout_data.title[3]}}
                    </v-col>
                    <v-col
                    class="col-content"
                    cols="8"
                    md="10">
                        {{checkout_data.content.get_cellphone}}
                    </v-col>
                </v-row>
                <v-row 
                v-if="this.$session.get('isdealer') != 1">
                    <v-col
                    class="col-title"
                    cols="">
                        {{checkout_data.title[4]}}
                    </v-col>
                    <v-col
                    class="col-content"
                    cols="8"
                    md="10"
                    v-html="checkout_data.content.paymode">
                    </v-col>
                </v-row>
                <v-row
                v-if="this.$session.get('isdealer') != 1">
                    <v-col
                    class="col-title"
                    cols="">
                        {{checkout_data.title[5]}}
                    </v-col>
                    <v-col
                    class="col-content"
                    cols="8"
                    md="10">
                        {{checkout_data.content.invoice_number}}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    class="col-title"
                    cols="">
                        {{checkout_data.title[6]}}
                    </v-col>
                    <v-col
                    class="col-content"
                    cols="8"
                    md="10">
                        <span class="red--text">$
                            {{(parseInt(checkout_data.content.totalmoney)+
                            parseInt(checkout_data.content.shipping_fee)-
                            checkout_data.content.discount).toString().replace(/\B(?=(\d{3})+$)/g,',')}}
                        </span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    class="col-title"
                    cols="">
                        {{checkout_data.title[7]}}
                    </v-col>
                    <v-col
                    class="col-content"
                    cols="8"
                    md="10">
                        {{checkout_data.content.order_state}}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    class="col-title"
                    cols="">
                        {{checkout_data.title[8]}}
                    </v-col>
                    <v-col
                    class="col-content"
                    cols="8"
                    md="10">
                        {{checkout_data.content.pos_mode}}
                        <span class="red--text ps-fsz14">{{checkout_data.content.get_address}}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    class="col-head"
                    cols="6">
                        商品明細
                    </v-col>
                    <v-col
                    class="col-head"
                    cols="3">
                        說明
                    </v-col>
                    <v-col
                    class="col-head"
                    cols="3">
                        退換貨
                    </v-col>
                </v-row>
                <v-row
                v-for="item in checkout_data.content.detail"
                :key="item.index">
                    <template v-if="item.ispurchase == 1">
                        <v-col
                        cols="6"
                        class="d-flex col-content border-l"
                        >
                            <div class="img-container">
                                <img :src="'/api/'+item.img" alt="">
                            </div>
                            <div class="content-container">
                                <p>{{ item.products_name }}</p>
                                <p>數量：<span class="black--text">{{item.stock}}</span>
                                </p>
                                <p>價格：<span class="black--text">$</span>
                                    <span class="red--text price">{{ item.price.toString().replace(/\B(?=(\d{3})+$)/g,',') }}</span> 
                                </p>
                            </div>
                        </v-col>
                        <v-col
                        class="col-content sub-row"
                        cols="3">
                        </v-col>
                        <v-col
                        class="col-content sub-row"
                        cols="3">
                        </v-col> 
                    </template>
                    <template v-else>
                        <v-col
                        cols="6"
                        class="d-flex col-content border-l"
                        >
                            <div class="img-container">
                                <img :src="'/api/'+item.img" alt="">
                            </div>
                            <div class="content-container">
                                <p>{{ item.products_name }}</p>
                                <p>顏色：<span class="black--text">{{ item.color_caption}}</span></p>
                                <p>尺寸：<span class="black--text">{{ item.size_caption}}</span></p>
                                <p>數量：<span class="black--text">{{item.stock}}</span>
                                </p>

                                <p>價格：<span class="black--text">$</span>
                                    <span class="red--text price">{{ item.price.toString().replace(/\B(?=(\d{3})+$)/g,',') }}</span> 
                                </p>
                            </div>
                        </v-col>
                        <v-col
                        class="col-content sub-row"
                        cols="3">
                        </v-col>
                        <v-col
                        class="col-content sub-row"
                        cols="3">
                        </v-col>
                    </template>
                </v-row>
            </v-container>
            <div class="button-container">
                <v-btn class="final-button" @click="goIndex">
                    完成購物
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import Pagenationtopimg from "../../components/pagenationimg/index.vue";
import Pagenationtop from "../../components/pagenationtop/index.vue";
import qs from 'qs'
export default {
    data(){
        return{
            pagenation_title:[
                '購物明細'
            ],
            checkout_data:{
                title:[
                    '訂購日期',
                    '收件人',
                    '送貨地址',
                    '手機號碼',
                    '付款方式',
                    '電子發票',
                    '總金額',
                    '訂單狀態',
                    '運送方式'
                ],
                content:[]
            },
        }
    },
    components: {
        Pagenationtopimg,
        Pagenationtop,
    },
    mounted(){
        this.getOrder()
    },
    methods:{
        getOrder(){
            this.axios.post('/api/car/get_order_detail',qs.stringify({
                ordernumber: this.$route.params.ordernumber,
                m_id: this.$session.get('memberId'),
                isdealer: this.$session.get('isdealer')
            }))
            .then(response => (
                console.log(response),
                this.checkout_data.content = response.data.data,
                this.$store.commit("closeOverlay")
            ))
        },
        goIndex(){
            this.$router.push({name:`MemberCenter`,
            params:{
                id: 2 ,
            }})
        }
    }
}
</script>

<style>

</style>