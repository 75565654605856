<template>
  <div class="size">
    <Pagenationtopimg/>
    <Pagenationtop :pagenation_title='pagenation_title' path='Size'/>
    <div class="size-container">
      <div class="btn-container">
          <button
          v-for="(item,index) in main_data"
          :key="item.index"
          @click="chang(index)">
              {{item.title}}
          </button>
      </div>
      <div class="size-content">
          <template 
          v-for="item in content_data">
            <div class="title-block"
            :key="item.index"
            @click="open_1 = !open_1"
            :class="{active:open_1}">
                <div>
                    {{ item.title }}
                </div>
                <i class="fas fa-arrow-circle-up"></i>
            </div>
            <v-expand-transition
            :key="item.index"
            v-if="open_1">
                <div class="img-container"
                v-html="item.content">
                </div>
            </v-expand-transition>
          </template>
      </div>
    </div>
  </div>
</template>

<script>
import Pagenationtopimg from "../components/pagenationimg/index.vue";
import Pagenationtop from '../components/pagenationtop/index.vue'
import qs from 'qs'
export default {
    data(){
        return{
            pagenation_title:[
                '尺寸與材質'
            ],
            open_1:true,
            open:0,
            main_data:[],
            content_data:[]
        }
    },
    components:{
        Pagenationtopimg,
        Pagenationtop,
    },
    mounted(){
        this.getData()
    },

    methods:{
        getData(){
            this.axios.post('api/qa/getsize_catalog',qs.stringify({
                lang:this.$store.state.lang
            }))
            .then(response => (
                console.log(response),
                this.main_data = response.data.result,
                this.chang(0),
                this.$store.commit('closeOverlay')
            ))
            .catch(error => (
                console.log(error),
                this.$store.commit('closeOverlay')
            ))
        },
        chang(index){
            this.open = index
            this.content_data = this.main_data[index].data
            console.log(index)
        }
    }
}
</script>

<style>

</style>