<template>
  <div class="newdetail">
    <Pagenationtopimg />
    <Pagenationtop :pagenation_title="pagenation_title" path="news" />
    <div class="newdetail-container">
        <div class="img-container">
            <img :src="'/api/upload/news/'+main_data.mainid+'/'+main_data.img" alt="">
        </div>
        <div class="content-container">
            <p class="ps-title">
                {{main_data.title}}
            </p>
            <hr>
            <div class="date">發布日期：{{main_data.date}}</div>
            <div v-html="main_data.content" class="tx">
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Pagenationtopimg from "../../components/pagenationimg/index.vue";
import Pagenationtop from "../../components/pagenationtop/index.vue";
export default {
    data(){
        return{
            pagenation_title:[
                '最新消息'
            ],
            main_data:null,
            test:{

            },
            language:'tw'
        }
    },

    mounted(){
        this.axios.get('/api/news/getnews_detail',{ 
                params:{lang : this.language , 
                mainid:this.$route.params.id},
                })
        .then(response => (
            console.log(response),
            this.main_data = response.data.result,
            this.$store.commit('closeOverlay')
            ))
        .catch(error => console.log(error))
    },

    components: {
        Pagenationtopimg,
        Pagenationtop,
    },
}
</script>

<style>

</style>