<template>
  <div class="shopcar">
    <Pagenationtopimg />
    <Pagenationtop :pagenation_title="pagenation_title" path="shopcar" />
    <div class="shopcar-container">
      <template v-if="user_main_car === undefined || user_main_car === null ||user_main_car.item === 0">
        <v-overlay :value="no_product_mask" class="no-product-mask"
        z-index="999">
            <div>目前無選購商品</div>
            <span>{{mask_time}}s後導向產品頁</span>
          <v-progress-circular
            indeterminate
            size="64"
          >
          </v-progress-circular>
        </v-overlay>
      </template>
      <template v-else>
        <v-overlay :value="overlay" z-index="999">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <template>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left black--text ps-title">訂單明細</th>
                  <th class="text-center black--text ps-title">小計</th>
                  <th class="text-center black--text ps-title">刪除</th>
                </tr>
              </thead>
              <tbody v-if="user_main_car != null">
                <tr v-for="(item, i) in user_main_car.item" :key="item.index">
                  <td>
                    <div class="td-container">
                      <div class="img-container">
                        <img
                          :src="'api/' + user_main_car[i + 1].p_pic"
                          alt=""
                        />
                      </div>
                      <div class="content-container">
                        <p>{{ user_main_car[i + 1].p_caption }}</p>
                        <p v-if="user_main_car[i + 1].color_caption">
                          顏色：<span class="black--text">{{
                            user_main_car[i + 1].color_caption
                          }}</span>
                        </p>
                        <p v-if="user_main_car[i + 1].size_caption">
                          尺寸：<span class="black--text">{{
                            user_main_car[i + 1].size_caption
                          }}</span>
                        </p>
                        <p v-if="user_main_car[i+1].ispurchase">
                          數量：
                          <span class="input-container-sp ispurchase">
                            <v-btn @click="minusAddProductNum(i+1,
                            user_main_car[i+1].p_mainid)">-</v-btn>
                            <input type="text"
                            v-model="user_main_car[i + 1].num"
                            value="user_main_car[i + 1].num"
                            readonly="true">
                            <v-btn @click="addAddProductNum(i+1,
                            user_main_car[i+1].p_mainid)">+</v-btn>
                          </span>
                        </p>
                        <p v-else>
                          數量：
                          <span class="input-container-sp">
                            <v-btn @click="minusCarNum(i+1,
                            user_main_car[i+1].p_mainid,
                            user_main_car[i+1].color_mainid,
                            user_main_car[i+1].size_mainid)">-</v-btn>
                            <input type="text"
                            v-model="user_main_car[i + 1].num"
                            value="user_main_car[i + 1].num"
                            readonly="true">
                            <v-btn @click="addCarNum(i+1,
                            user_main_car[i+1].p_mainid,
                            user_main_car[i+1].color_mainid,
                            user_main_car[i+1].size_mainid)">+</v-btn>
                          </span>
                        </p>
                        <p>
                          價格：<span class="black--text">$</span>
                          <span class="red--text price">
                            {{
                            user_main_car[i + 1].price.toString().replace(/\B(?=(\d{3})+$)/g,',') }}</span>
                        </p>
                      </div>
                    </div>
                    <template v-if="user_main_car[i+1].onsale_title">
                      <div class="onsale_title">
                        {{user_main_car[i+1].onsale_title}}
                      </div>
                    </template>
                  </td>
                  <td class="text-center">
                    $
                    <span class="red--text price" >
                      {{
                        (user_main_car[i + 1].price * user_main_car[i + 1].num).toString().replace(/\B(?=(\d{3})+$)/g,',')
                      }}
                    </span>
                  </td>
                  <td class="text-center">
                    <v-btn
                    v-if="user_main_car[i + 1].specifi_id"
                      @click="
                        deleteShopCar(i + 1, user_main_car[i + 1].specifi_id)
                      "
                      text
                      icon
                    >
                      <v-icon>fas fa-times</v-icon>
                    </v-btn>
                    <v-btn
                    v-else
                      @click="
                        deleteShopCar(i + 1, user_main_car[i + 1].p_id)
                      "
                      text
                      icon
                    >
                      <v-icon>fas fa-times</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
        <template v-if="user_main_car">
          <v-scroll-x-reverse-transition
          v-for="item in user_main_car.discount_caption" 
          :key="item.index">
            <template v-if="user_main_car.discount_caption" >
                <div class="discount-caption "  
                >
                  <v-icon
                    color="green darken-2"
                    right
                  >
                    mdi-checkbox-marked-circle
                  </v-icon>
                  {{item}}
                </div>
            </template>
          </v-scroll-x-reverse-transition>
        </template>
        <template v-if="user_main_car.salecode_code != undefined">
          <h3 class="text-right text-decoration-line-through">
            商品總金額
            <span v-if="user_main_car != null" class="text-decoration-line-through grey--text"
              >${{ totalamount.toString().replace(/\B(?=(\d{3})+$)/g,',') }}</span
            >
            元
          </h3>
          <h3 class="title-price">
            折扣優惠價
            <span v-if="user_main_car != null" class="red--text"
              >${{ (totalamount-user_main_car.salecode_money).toString().replace(/\B(?=(\d{3})+$)/g,',') }}</span
            >
            元
          </h3>
        </template>
        <template v-else>
          <h3 class="title-price">
            商品總金額
            <span v-if="user_main_car != null" class="red--text"
              >${{ totalamount.toString().replace(/\B(?=(\d{3})+$)/g,',') }}</span
            >
            元
          </h3>
        </template>
        <v-container>
          <v-row class="add-shop">
            <v-col cols="12 col-head">
              <span>加購價商品</span>
            </v-col>
            <v-col
              cols="12"
              lg="6"
              v-for="(item,i) in desserts"
              class="d-flex"
              :key="item.index"
            >
              <div class="img-container">
                <img :src="'api/'+item.p_pic" alt="" />
              </div>
              <div class="content-container">
                <p>{{ item.p_caption }}</p>
                <p >
                  數量：
                  <span class="input-container-sp">
                    <v-btn @click="minusAddproduct(i)">-</v-btn>
                    <input type="text"
                    v-model="item.stock"
                    value="item.stock"
                    readonly="true">
                    <v-btn @click="addAddproduct(i)">+</v-btn>
                  </span>
                </p>

                <p>
                  價格：<span class="black--text">$</span>
                  <span class="red--text price">{{ item.price }}</span>
                </p>
              </div>
              <div class="button-container">
                <v-btn @click="addShopCar(item.p_mainid,item.pm_id,item.stock)"> 放入購物車 </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row class="personal-data">
            <v-col class="col-head" cols="12"> 訂購人資料 </v-col>
            <v-col cols="3" md="2"> 姓名 </v-col>
            <v-col cols="9" md="10">
              {{ personal_data.name }}
            </v-col>
            <v-col cols="3" md="2"> E-mail </v-col>
            <v-col cols="9" md="10">
              {{ personal_data.email }}
            </v-col>
            <v-col cols="3" md="2"> 聯絡電話 </v-col>
            <v-col cols="9" md="10">
              {{ personal_data.home_phone }}
            </v-col>
            <v-col cols="3" md="2"> 手機 </v-col>
            <v-col v-if="this.$session.get('isdealer') != 1" cols="9" md="10">
              {{ personal_data.phone }}
            </v-col>
            <v-col v-else cols="9" md="10">
              {{ this.$session.get('memberPhone') }}
            </v-col>
            <v-col cols="3" md="2"> 地址 </v-col>
            <v-col cols="9" md="10">
              {{ personal_data.city }}
              {{ personal_data.town }}
              {{ personal_data.address }}
            </v-col>
          </v-row>
        </v-container>
        <v-container 
        v-if="this.$session.get('isdealer') != 1">
          <v-row class="buy-personal">
            <v-col class="col-head" cols="12"> 收件人資料 </v-col>
            <v-col cols="12" class="check">
              <v-checkbox @click="sampleInput" :label="`同訂購人`"></v-checkbox>
            </v-col>
            <v-col cols="3" md="2"> <span class="tx-red">*</span>姓名 </v-col>
            <v-col cols="9" md="10">
              <input
                v-model="receive.name"
                class="input"
                type="text"
                name="name"
                placeholder="請輸入姓名"
              />
            </v-col>
            <v-col cols="3" md="2"> <span class="tx-red">*</span>E-mail </v-col>
            <v-col cols="9" md="10">
              <input
                v-model="receive.email"
                class="input"
                type="text"
                name="email"
                placeholder="請輸入信箱"
              />
            </v-col>
            <v-col cols="3" md="2"> 聯絡電話 </v-col>
            <v-col cols="9" md="10">
              <input
                v-model="receive.home_phone"
                class="input"
                type="text"
                name="phone"
                placeholder="請輸入聯絡號碼"
              />
            </v-col>
            <v-col cols="3" md="2"> <span class="tx-red">*</span>手機 </v-col>
            <v-col cols="9" md="10">
              <input
                v-model="receive.phone"
                class="input"
                type="text"
                name="phone"
                placeholder="請輸入手機號碼"
              />
            </v-col>
            <v-col cols="3" md="2"> <span class="tx-red">*</span>地址 </v-col>
            <v-col cols="9" md="10">
              <v-select
                v-model="receive.city"
                menu-props="auto"
                height="43"
                :value="receive.city"
                placeholder="請選擇"
                :items="cityname"
                dense
                outlined
              ></v-select>
              <v-select
                menu-props="auto"
                v-model="receive.town"
                height="43"
                placeholder="請選擇"
                :value="receive.town"
                :items="townname"
                dense
                outlined
              ></v-select>
              <input
                v-model="receive.address"
                class="add input"
                type="text"
                name="address"
                placeholder="請輸入詳細地址"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-container 
        v-if="this.$session.get('isdealer') != 1">
          <v-row class="pay">
            <v-col class="col-head" cols="12"> 付款方式 </v-col>
            <template  v-for="(n,i) in how_pay" >
              <!-- 貨到付款隱藏 -->
              <template v-if="i == 1">
                <template v-if="totalamount+parseInt(pos_money)-total_discount < 199999">
                  <v-col class="dp-none" cols="12" :key="n.index" >
                      <v-radio-group v-model="pay_check">
                        <v-radio :label="n" :value="i" color="black"></v-radio>
                      </v-radio-group>
                  </v-col>
                </template>
              </template>
              <!-- ATM -->
              <template v-else-if="i == 2">
                <template v-if="totalamount+parseInt(pos_money)-total_discount < 199999">
                  <v-col cols="12" :key="n.index" >
                      <v-radio-group v-model="pay_check">
                        <v-radio :label="n" :value="i" color="black"></v-radio>
                      </v-radio-group>
                  </v-col>
                </template>
              </template>
              <!-- 超商條碼 -->
              <template v-else-if="i == 3">
                <template v-if="totalamount+parseInt(pos_money)-total_discount < 20000">
                  <v-col cols="12" :key="n.index" >
                      <v-radio-group v-model="pay_check">
                        <v-radio :label="n" :value="i" color="black"></v-radio>
                      </v-radio-group>
                  </v-col>
                </template>
              </template>
              <!-- 超商代碼 -->
              <template v-else-if="i == 4">
                <template v-if="totalamount+parseInt(pos_money)-total_discount < 6000">
                  <v-col cols="12" :key="n.index" >
                      <v-radio-group v-model="pay_check">
                        <v-radio :label="n" :value="i" color="black"></v-radio>
                      </v-radio-group>
                  </v-col>
                </template>
              </template>
              <template v-else>
                <v-col cols="12" :key="n.index">
                    <v-radio-group v-model="pay_check">
                      <v-radio :label="n" :value="i" color="black"></v-radio>
                    </v-radio-group>
                </v-col>
              </template>
            </template>
          </v-row>
        </v-container>
        <v-container>
          <v-row class="choice">
            <v-col class="col-head" cols="12"> 選擇配送方式 </v-col>
            <!-- d-flex -->
            <v-col cols="12" class=" align-center dp-none"
            v-if="this.$session.get('isdealer') != 1">
              <v-radio-group v-model="how_transport.number">
                <v-radio
                  :label="how_transport.content[0]"
                  :value="1"
                  :disabled="map_data.CVSStoreName"
                  color="black"
                ></v-radio>
              </v-radio-group>
              <!-- <span class="red--text ml-2">(台中市南屯區忠勇路71-7號)</span> -->
            </v-col>
            <template v-if="pay_check != 1  || 
                      this.$session.get('isdealer') == 1">
                      <!-- d-flex -->
              <v-col class="d-flex align-center" cols="12">
                <v-radio-group v-model="how_transport.number">
                  <v-radio
                    :label="how_transport.content[1]"
                    :value="2"
                    :disabled="map_data.CVSStoreName"
                    color="black"
                  ></v-radio>
                </v-radio-group>
                <span class="red--text ml-2">(僅寄送、無貨到付款服務)</span>
              </v-col>
              <v-col cols="12" class="check"
              v-if="this.$session.get('isdealer') === 1">
                <v-checkbox @click="sampleInputAddress" :label="`同訂購人`"></v-checkbox>
              </v-col>
              <v-col 
              v-if="this.$session.get('isdealer') === 1"
              class="d-flex aling-center" cols="12">
                <input
                  v-model="receive.address"
                  class="add input"
                  type="text"
                  name="address"
                  placeholder="請輸入詳細地址"
                />
              </v-col>
            </template>
            <template v-if="user_main_car.isbusiness == 1 &&
                            this.$session.get('isdealer') != 1">
              <v-col cols="12" class="choice-store">
                <div class="radio-container">
                  <v-radio-group v-model="how_transport.number">
                    <v-radio
                      :label="how_transport.content[2]"
                      :value="3"
                      color="black"
                    ></v-radio>
                  </v-radio-group>
                </div>
                
                  <div class="button-container" v-if="how_transport.number != 3">
                    <v-btn
                      v-for="(item, item_index) in btn_store"
                      :key="item.index"
                      disabled
                      @click="btnDeliveryActive(item_index)"
                      :class="{ active: item_index === btn_delivery }"
                    >
                      {{ item.title }}
                      <span class="red--text">
                        {{ item.content }}
                      </span>
                    </v-btn>
                  </div>
                  <div class="button-container" v-else>
                    <template v-if="pay_check == 1">
                      <v-btn
                        v-for="(item, item_index) in btn_store"
                        :key="item.index"
                        @click="btnDeliveryActive(item_index)"
                        :disabled="map_data.CVSStoreName"
                        :class="{ active: item_index === btn_delivery }"
                      >
                        {{ item.title }}
                        <span class="red--text">
                          {{ item.content }}
                        </span>
                      </v-btn>
                    </template>
                    <template v-else>
                      <v-btn
                        v-for="(item, item_index) in btn_store"
                        :key="item.index"
                        @click="btnDeliveryActive(item_index)"
                        :disabled="map_data.CVSStoreName || 
                        item_index == 1 || 
                        item_index == 3 ||
                        item_index == 5 ||
                        item_index == 7"
                        :class="{ active: item_index === btn_delivery }"
                      >
                        {{ item.title }}
                        <span class="red--text">
                          {{ item.content }}
                        </span>
                      </v-btn>
                    </template>
                    <div class="single-button">
                      <v-btn @click="mapSelect"
                      v-if="btn_delivery != null"
                      :disabled="map_data.CVSStoreName"> 選擇取貨門市 </v-btn>
                    </div>
                    <div class="map_data"
                    v-if="map_data.CVSStoreName != null">
                      <span>
                        取貨門市：{{btn_store[btn_delivery].title}}
                        {{btn_store[btn_delivery].content}}
                        {{map_data.CVSStoreName}}
                      </span>
                    </div>
                  </div>
                
              </v-col>
            </template>
          </v-row>
        </v-container>
        <v-container 
        v-if="this.$session.get('isdealer') != 1">
          <v-row class="invoice">
            <v-col class="col-head" cols="12"> 發票資料 </v-col>
            <v-col cols="3">
              <span>個人電子發票</span>
            </v-col>
            <v-col cols="9">
              <div class="check-container">
                <v-radio-group v-model="unkind.checkbox" row>
                  <v-radio
                    color="black"
                    label="二聯式電子發票證明聯(紙本)"
                  ></v-radio>
                  <v-radio
                    color="black"
                    label="三聯式電子發票證明聯(紙本)"
                  ></v-radio>
                  <v-radio
                    color="black"
                    label="手機條碼載具"
                  ></v-radio>
                </v-radio-group>
              </div>
            </v-col>
              <v-col cols="12" >
                <v-slide-x-reverse-transition mode="out-in">
                  <div class="input-container" v-if="unkind.checkbox === 2" key="block1">
                    <input class="input mobile" type="text" placeholder="請輸入手機條碼載具編號" v-model="unkind.vehicle"/>
                  </div>
                  <div v-else key="block2" class="d-flex">
                    <div class="input-container" >
                      <span>公司統編</span>
                      <input class="input" type="text" placeholder="請輸入公司統編" v-model="unkind.uniform"/>
                    </div>
                    <div class="input-container" >
                      <span>發票抬頭</span>
                      <input class="input" type="text" placeholder="請輸入發票抬頭" v-model="unkind.title"/>
                    </div>
                  </div>
                </v-slide-x-reverse-transition>
              </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row class="selfnumber">
            <v-col class="col-head" cols="12"> 輸入折扣碼 </v-col>
            <v-col cols="12">
              <span>折扣碼</span>
              <template v-if="user_main_car.salecode_code != undefined">
                <input disabled  class="input" type="text" :placeholder="user_main_car.salecode_code">
                <v-btn  @click="deleteCoupon()">清除折扣碼</v-btn>
              </template>
              <template v-else>
                <input  v-model="self_code" class="input" type="text" placeholder="請輸入折扣碼">
                <v-btn @click="checkCoupon()">確認折扣碼</v-btn>
              </template>
              <v-select
                :disabled="user_main_car.salecode_code != undefined"
                v-model="self_code"
                menu-props="auto"
                height="43"
                placeholder="選擇現有的折扣碼"
                :items="get_coupon_list"
                item-text="cpation"
                :item-disabled="checkIsItemDisabled"
                dense
                outlined
                @change="checkCoupon()"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row class="total_money" align="center" justify="end">
            <span class="w-100px">
              商品總金額
            </span>
            <span class="w-50px">
              ${{totalamount.toString().replace(/\B(?=(\d{3})+$)/g,',')}}
            </span>
            <span>
              元
            </span>
          </v-row>
          <v-row class="total_money" align="center" justify="end">
            <span class="w-100px">
              折扣
            </span>
            <span class="w-50px">
              $-{{total_discount.toString().replace(/\B(?=(\d{3})+$)/g,',')}}
            </span>
            <span>
              元
            </span>
          </v-row>
          <v-row class="total_money" align="center" justify="end" v-if="user_main_car.salecode_money != undefined">
            <span class="w-100px">
              折扣碼
            </span>
            <span class="w-50px">
              $-{{user_main_car.salecode_money.toString().replace(/\B(?=(\d{3})+$)/g,',')}}
            </span>
            <span>
              元
            </span>
          </v-row>
          <v-row class="total_money" align="center" justify="end">
            <span class="w-100px">
              運費
            </span>
            <span class="w-50px" v-if="this.user_main_car.is_fee_freight">
              0
            </span>
            <span v-else class="w-50px">
              ${{ pos_money.toString().replace(/\B(?=(\d{3})+$)/g,',') }}
            </span>
            <span>
              元
            </span>
          </v-row>
        </v-container>
        <h3 class="title-price transport" v-if="user_main_car.salecode_money != undefined">
          總計
          <span v-if="user_main_car.discount_money" class="red--text">${{ (totalamount+parseInt(pos_money)-user_main_car.salecode_money-total_discount).toString().replace(/\B(?=(\d{3})+$)/g,',') }}
          </span> 
          <span v-else class="red--text">${{ (totalamount+parseInt(pos_money)-user_main_car.salecode_money).toString().replace(/\B(?=(\d{3})+$)/g,',') }}</span> 
          元
        </h3>
        <h3 class="title-price transport" v-else>
          總計
          <span v-if="user_main_car.discount_money" 
                class="red--text">
                ${{ (totalamount+parseInt(pos_money)-total_discount).toString().replace(/\B(?=(\d{3})+$)/g,',') }}
          </span> 
          <span v-else class="red--text">${{ (totalamount+parseInt(pos_money)).toString().replace(/\B(?=(\d{3})+$)/g,',') }}</span> 
          元
        </h3>

        <div class="bottom-button-container">
          <v-btn @click="goShop"> 繼續購物 </v-btn>
          <v-btn @click="checkOut"> 確認結帳 </v-btn>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Pagenationtopimg from "../../components/pagenationimg/index.vue";
import Pagenationtop from "../../components/pagenationtop/index.vue";
import cityName_data from "@/assets/citydata.json";
import qs from "qs";
export default {
  data() {
    return {
      self_code:null,
      map_data:{
        CVSStoreID:null,
        CVSStoreName:null,
        CVSAddres:null,
        CVSTelephone:null,
      },
      order_list_data:{

      },
      pos_money:0,
      no_product_mask:true,
      mask_time:3,
      overlay: true,
      snackbar: false,
      cityname: [],
      townname: [],
      unkind:{
        checkbox: 0,
        uniform:null,
        title:null,
        vehicle:null
      },
      btn_delivery: null,
      pay_check: 0,
      how_pay: [
        "信用卡VISA、Master、JCB/VISA金融卡",
        "貨到付款",
        "ATM銀行轉帳",
        "超商條碼代收",
        "超商代碼繳費",
      ],
      
      how_transport: {
        number: 1,
        content: ["台中門市取貨", "宅配", "超商取貨"],
        get_delivery:null
      },
      radioGroup: 1,
      items: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      pagenation_title: ["購物車"],
      personal_data: {
        name: null,
        email: null,
        home_phone: null,
        phone: null,
        city: null,
        town: null,
        address: null,
      },
      receive: {
        name: '',
        email: '',
        home_phone: '',
        phone: '',
        city: '',
        town: '',
        address: '',
      },
      desserts:null,
      user_main_car: null,
      delivery: null,
      how_delivery: null,
      totalamount:0, //總計
      total_discount:0, //活動折扣統計
      get_coupon_list:[], //優惠碼
    };
  },
  computed: {
    btn_store() {
      return [
        {
          title: "7-11取貨",
          content: "(先付款)",
          type:"7-11"
        },
        {
          title: "7-11",
          content: "(貨到付款)",
          type:"7-11"
        },
        {
          title: "全家取貨",
          content: "(先付款)",
          type:"全家"
        },
        {
          title: "全家",
          content: "(貨到付款)",
          type:"全家"
        },
        {
          title: "萊爾富",
          content: "(先付款)",
          type:"萊爾富"
        },
        {
          title: "萊爾富",
          content: "(貨到付款)",
          type:"萊爾富"
        },
        {
          title: "OK",
          content: "(先付款)",
          type:"OK"
        },
        {
          title: "OK",
          content: "(貨到付款)",
          type:"OK"
        },
      ];
    },
    user_main_car_c(){
      return JSON.parse(JSON.stringify(this.user_main_car))
    }
  },
  components: {
    Pagenationtopimg,
    Pagenationtop,
  },
  created() {
    this.$store.commit('closeOverlay')
    //歷史紀錄
    this.receive.name = this.$session.get("receive_name");
    this.receive.email = this.$session.get("receive_email");
    this.receive.home_phone = this.$session.get("receive_home_phone");
    this.receive.phone = this.$session.get("receive_phone");
    this.receive.city = this.$session.get("receive_city");
    this.receive.town = this.$session.get("receive_town");
    this.receive.address = this.$session.get("receive_address");
    //會員資料
    this.personal_data.name = this.$session.get('memberName');
    this.personal_data.email = this.$session.get('memberEmail');
    this.personal_data.home_phone = this.$session.get('memberTel');
    this.personal_data.phone = this.$session.get('memberAccount');
    this.personal_data.city = this.$session.get('memberCity');
    this.personal_data.town = this.$session.get('memberTown');
    this.personal_data.address = this.$session.get('memberAddress');
    this.btn_delivery = this.$session.get("btn_delivery")
    this.user_main_car = this.$session.get("shopcar");
    console.log(this.user_main_car)
    if(this.user_main_car === undefined ||
      this.user_main_car === null||
      this.user_main_car.item === 0){
      this.masktimeout = setInterval(this.maskTime, 1000)
      this.timeout = setTimeout(this.goShoppingCenter, 3000)
    }
    else{
      this.getAddProduct();
      this.getDelivery();
      this.getMapShop();
      this.citySelect();
      this.getCouponList();
    }

    if(this.$session.get('isdealer')){
      this.how_transport.number = 2
    }
  },
  mounted() {
    console.log("-shopcar-下面這個-")
    console.log(this.$session.get("shopcar"))
    this.getShopCar()
    if(this.user_main_car.discount_money){
      this.discountMoneyAmount()
    }
    if(this.user_main_car.is_fee_freight){
      this.post_money = 0
    }
  },
  beforeDestroy(){
      clearTimeout(this.timeout)
      clearTimeout(this.masktimeout)
  },
  watch: {
    "receive.city": function () {
      this.townname.splice(0, this.townname.length);
      let index = this.cityname.indexOf(this.receive.city);
      console.log(index);
      if (this.receive.city === undefined) {
        console.log('nono')
      }
      else{
        cityName_data[index].AreaList.forEach((element) => {
          this.townname.push(element.AreaName);
        });
      }

    },
    "pos_money":function(){
      if(this.user_main_car.is_fee_freight){
        this.pos_money = 0
      }
    },
    "how_transport.number": function(){
      if(this.how_transport.number === 2 && 
        this.$session.get('isdealer') != 1){
        this.pos_money = this.how_transport.get_delivery.home_delivery_fee
      }
      else {
        this.pos_money = 0
      }
    },
    "btn_delivery": function(){
      if ( this.btn_delivery === 0 || this.btn_delivery ===1){
        this.pos_money = this.how_transport.get_delivery.seven_fee
      }
      else if (this.btn_delivery === 2 || this.btn_delivery ===3){
        this.pos_money = this.how_transport.get_delivery.family_fee
      }
      else {
        this.pos_money = 0
      }
    },
    'user_main_car':{
      handler(){
        if(this.user_main_car){
          this.totalamount = 0
          for(let i = 0 ; i < this.user_main_car.item; i++){
            this.totalamount  = parseInt(this.user_main_car[i+1].price) * parseInt(this.user_main_car[i+1].num) + this.totalamount
          }
        }
      },
      deep:true,
      immediate : true
    },
  },
  methods: {
    getMapShop(){
      let vm = this
      this.axios.post('/api/ecpay/get_map')
      .then(function(response){
        if(response.data.status == false){
          console.log(response)
          vm.overlay = false
        }
        else{
          console.log(response.data),
          vm.map_data = response.data.map_data
          vm.how_transport.number = 3
          vm.overlay = false
        }
        })
    },
    //檢查是否可使用
    checkIsItemDisabled(item) {
      return (item.isuse != 0)
    },
    //優惠券API
    getCouponList(){
      let vm = this 
      vm.axios.post('/api/member/get_coupon_list', qs.stringify({
        m_id: this.$session.get('memberId'),
      }))
      .then(function(responese)  {
        console.log('----優惠碼')
        console.log(responese),
        vm.get_coupon_list = responese.data.data
        //還沒使用過的往上擺
        if(vm.get_coupon_list != undefined){
          vm.get_coupon_list.forEach(function(element){
            if(element.use_edate != null && element.isuse != 1){
              let element_date = new Date(element.use_edate)
              let new_date = new Date()
              if(element_date < new_date){
                console.log(element_date+'、'+new_date)
                element.isuse = 2
              }
            } 
          })
        }
        vm.get_coupon_list.sort(function (a,b){
          return a.isuse - b.isuse
        })
        let cpation = vm.get_coupon_list.map(item => item.cpation)
        let isuse = vm.get_coupon_list.map(item => item.isuse)
        let code = vm.get_coupon_list.map(item => item.code)
        console.log(isuse)
        vm.get_coupon_list = []
        cpation.forEach(function(element , index){
          vm.get_coupon_list[index] = {
              cpation:element,
              isuse:isuse[index],
              value:code[index]
          }
        })
      })
    },
    // 提取購物車資料
    getShopCar(){
        console.log('getShop')
        let vm = this
        vm.axios.post('/api/car/show_car', qs.stringify({
            lang: vm.$store.state.lang,
            isdealer: vm.$session.get('isdealer'),
        }))
        .then(function(response)  {
          console.log(response)
        })
        .catch(err => (
            alert(err)
        ))
    },
    citySelect() {
      cityName_data.forEach((element) => {
        this.cityname.push(element.CityName);
      });
      let index = this.cityname.indexOf(this.receive.city);
      if (this.receive.city === undefined) {
        console.log("city null");
      } 
      else {
        cityName_data[index].AreaList.forEach((element) => {
          this.townname.push(element.AreaName);
        });
      }
    },
    getAddProduct() {
      this.axios
        .post("/api/car/get_purchase")
        .then((response) => this.desserts = response.data.data);
    },
    getDelivery() {
      this.axios.post('/api/car/get_delivery',qs.stringify({
        lang:this.$store.state.lang
      }))
      .then(response => (
        this.how_transport.get_delivery = response.data.data
      ))
    },
    checkRadio(index) {
      for (let i = 0; i < this.checkbox.length; i++) {
        if (i === index) {
          continue;
        }
        this.checkbox[i] = false;
      }
    },
    goShop() {
      this.$router.push({
        name: `ShoppingCenter`,
        params: { fid: "202106220001", sid: "202106220001" },
      });
    },
    goCheckout() {
      this.$router.push("Finalshopcar");
    },
    sampleInput() {
      this.receive.name = this.personal_data.name;
      this.receive.email = this.personal_data.email;
      this.receive.home_phone = this.personal_data.home_phone;
      this.receive.phone = this.personal_data.phone;
      this.receive.city = this.personal_data.city;
      this.receive.town = this.personal_data.town;
      this.receive.address = this.personal_data.address;
    },
    sampleInputAddress(){
      this.receive.address = this.personal_data.city + 
      this.personal_data.town +
      this.personal_data.address ;
    },
    mapSelect() {
      console.log(this.btn_delivery+1)
      let delivery = this.btn_delivery+1 //直接給btn沒反應
      if(this.btn_delivery != null){
        this.$session.start();
        this.$session.set("receive_name", this.receive.name);
        this.$session.set("receive_email", this.receive.email);
        this.$session.set("receive_home_phone", this.receive.home_phone);
        this.$session.set("receive_phone", this.receive.phone);
        this.$session.set("receive_city", this.receive.city);
        this.$session.set("receive_town", this.receive.town);
        this.$session.set("receive_address", this.receive.address);
        this.$session.set("btn_delivery",this.btn_delivery,)
        this.axios
          .post(
            "/api/ecpay/to_map",
            qs.stringify({
              delivery: this.btn_delivery+1,
            })
          )
          .then(
            (response) => (
              window.location.href = "/api/ecpay/to_map?delivery="+delivery,
              // (window.location.href = "/api/ecpay/to_map"),
              console.log(response)
            )
          );
      }
      else{
        alert("請先選擇取貨方式")
      }

    },
    btnDeliveryActive(index) {
      this.btn_delivery = index;
    },
    //刪除購物車品項
    deleteShopCar(index, id) {
      let vm = this;
      vm.overlay = true;
      vm.axios
        .post(
          "/api/car/del_prouductscar",
          qs.stringify({
            amount: index,
            p_id: id,
            item: vm.user_main_car.item,
            m_id: vm.$session.get('memberId'),
            lang: vm.$store.state.lang
          })
        )
        .then(
          function(response){
            if(response.data.msg === "刪除失敗!"){
              alert(response.data.msg)
            }
            else{
              vm.$session.set("shopcar", response.data.car)
              if(response.data.car){
                vm.$store.commit('shopcarItem',response.data.car.item)
              }
              else{
                vm.$store.commit('shopcarItem',null)
              }
            }
            window.location.reload()
          } 
        );
    },
    maskTime(){
      this.mask_time = this.mask_time-1
    },
    //前往購物中心
    goShoppingCenter() {
      this.$router.push({
        name: `ShoppingCenter`,
        params: { fid: "202109140001", sid: "202109140001" },
      });
    },
    //加入購物車
    addShopCar(p_mainid,pm_id,num){
        this.overlay = true,
        this.axios.post('/api/car/add_car_purchase', qs.stringify({
            lang: this.$store.state.lang,
            m_id: this.$session.get('memberId'),
            p_mainid: p_mainid,
            pm_id: pm_id,
            num: num,
        }))
        .then(response => (
            console.log(response),
            this.$session.set('shopcar',response.data.car),
            this.$store.commit('shopcarItem', response.data.car.item),
            window.location.reload()
        ))
        .catch(err => (
            alert('加入購物車失敗，您沒有選擇顏色或尺寸'+err)
        ))
    },
    addAddproduct(index){
      this.desserts[index].stock = parseInt(this.desserts[index].stock)  + 1
    },
    minusAddproduct(index){
      if(this.desserts[index].stock == 1){
        console.log('不可小於1')
      }
      else{
        this.desserts[index].stock = parseInt(this.desserts[index].stock)  - 1
      }
    },
    addAddProductNum(index,p_mainid){
      let vm = this
      let num = parseInt(this.user_main_car[index].num)  + 1
      vm.axios.post('/api/car/add_car_purchase',qs.stringify({
        lang: this.$store.state.lang,
        p_mainid: p_mainid,
        m_id: this.$session.get('memberId'),
        num: num
      }))
      .then(response => (
        this.user_main_car[index].num = parseInt(this.user_main_car[index].num)  + 1,
        vm.$session.set('shopcar',response.data.car),
        vm.user_main_car = response.data.car,
        console.log(response.data.car)
      ))
    },
    minusAddProductNum(index,p_mainid){
      let vm = this
      let num = parseInt(this.user_main_car[index].num)  - 1
      vm.axios.post('/api/car/add_car_purchase',qs.stringify({
        lang: vm.$store.state.lang,
        p_mainid: p_mainid,
        m_id: this.$session.get('memberId'),
        num: num
      }))
      .then(response => (
        console.log(response),
        vm.user_main_car[index].num = parseInt(vm.user_main_car[index].num)  - 1,
        vm.$session.set('shopcar',response.data.car),
        vm.user_main_car = response.data.car
      ))
    },
    addCarNum(index,p_mainid,c_mainid,s_mainid){
      let vm = this
      let num = parseInt(vm.user_main_car[index].num)  + 1
      this.axios.post('/api/car/add_car',qs.stringify({
        lang: this.$store.state.lang,
        m_id: this.$session.get('memberId'),
        p_mainid: p_mainid,
        c_mainid: c_mainid,
        s_mainid: s_mainid,
        num: num
      }))
      .then(
        function(response){
          if(response.data.msg == '產品庫存不足!'){
            alert(response.data.msg)
          }
          else{
            vm.user_main_car[index].num = parseInt(vm.user_main_car[index].num)  + 1
            vm.$session.set('shopcar',response.data.car),
            vm.user_main_car = response.data.car
            console.log(response)
            if(vm.user_main_car.discount_money){
              console.log('money')
              vm.discountMoneyAmount()
            }
            
          }
        }
      )
    },
    minusCarNum(index,p_mainid,c_mainid,s_mainid){
      let vm = this
      let num = parseInt(this.user_main_car[index].num)  - 1
      if(this.user_main_car[index].num == 1){
        console.log('不可小於1')
      }
      else{
        this.axios.post('/api/car/add_car',qs.stringify({
          lang: this.$store.state.lang,
          m_id: this.$session.get('memberId'),
          p_mainid: p_mainid,
          c_mainid: c_mainid,
          s_mainid: s_mainid,
          num: num
        }))
        .then(function(response){
              vm.user_main_car[index].num = parseInt(vm.user_main_car[index].num)  - 1,
              vm.$session.set('shopcar',response.data.car),
              vm.user_main_car = response.data.car,
              console.log(response)
              if(vm.user_main_car.discount_money){
                console.log('money')
                vm.discountMoneyAmount()
              }
              else{
                vm.total_discount = 0
              }
          } )
      }
    },
    //清除折扣碼
    deleteCoupon(){
      this.axios.post('/api/car/cancel_coupon')
      .then(response => (
          console.log(response),
          alert(response.data.msg),
          this.user_main_car = response.data.data,
          this.$session.set('shopcar',response.data.data)
          
      ))
    },
    //確認優惠碼
    checkCoupon(){
      let vm = this
      vm.axios.post('/api/car/count_coupon',qs.stringify({
        lang: vm.$store.state.lang,
        salecode: vm.self_code,
        m_id: vm.$session.get('memberId')
      }))
      .then(function(response) {
        console.log(response)
        if(response.data.status === false){
          alert(response.data.msg)
        }
        else{
          alert(response.data.msg)  
          vm.user_main_car = response.data.car
          vm.$session.set('shopcar',response.data.car)
          // window.location.reload()
        }
      })
    },
    //活動折扣總和
    discountMoneyAmount(){
      let vm = this
      vm.total_discount = 0
      vm.user_main_car.discount_money.forEach(element =>(
        console.log(element),
        vm.total_discount = vm.total_discount+element,
        console.log(vm.total_discount)
      ))
      vm.totalamount = vm.totalamount-vm.total_discount
      console.log(vm.totalamount)
    },
    //結帳
    checkOut(){
      let vm = this
      if(
        this.receive.name != '' &&
        this.receive.name != undefined &&
        this.receive.email != '' &&
        this.receive.email != undefined &&
        this.receive.phone != '' &&
        this.receive.phone != undefined &&
        this.receive.city != '' &&
        this.receive.city != undefined &&
        this.receive.town != '' &&
        this.receive.town != undefined &&
        this.receive.address != '' &&
        this.receive.address != undefined ){
        if(vm.totalamount <= 0){
          alert("結帳金額不可為0元")
        }
        else{
          let check = confirm('確定付款');
          if (check) {
            if(vm.$session.get('isdealer') === 1 ){
              vm.axios.post('/api/car/addto_order',qs.stringify({
                lang: vm.$store.state.lang,
                m_id: vm.$session.get('memberId'),
                name: vm.$session.get('memberName'),
                email: vm.$session.get('memberEmail'),
                cellphone: vm.$session.get('memberTel'),
                tel: vm.$session.get('memberTel'),
                address:vm.$session.get('memberCity')+vm.$session.get('memberTown')+vm.$session.get('memberAddress'),
                city:vm.$session.get('memberCity'),
                town:vm.$session.get('memberTown'),
                address_way:vm.$session.get('memberAddress'),
                pos_mode: vm.how_transport.number,
                pos_money: vm.pos_money,
                isdealer: vm.$session.get('isdealer'),
                is_use_salecode:1
              }))
              .then(function(response)  {
                    console.log(response.data)
                    if(response.data.status === true &&
                      vm.pay_check+1 == 2){
                      vm.user_main_car = null,
                      vm.$session.remove("shopcar"),
                      vm.$store.commit('shopcarItem',null)
                      vm.$router.push({name:"Finalshopcar", params: {
                        ordernumber:response.data.ordernumber
                      }})
                    }
                    else if (response.data.status === true){
                      (window.location.href = 
                      "/api/ecpay/to_ecpay?ordernumber="+response.data.ordernumber)
                      vm.user_main_car = null,
                      vm.$session.remove("shopcar")
                    }
                    else{
                      alert(response.data.msg)
                    }
              })
            }
            else if (vm.user_main_car.salecode_code != undefined){
              vm.axios.post('/api/car/addto_order',qs.stringify({
                lang: vm.$store.state.lang,
                m_id: vm.$session.get('memberId'),
                name: vm.$session.get('memberName'),
                email: vm.$session.get('memberEmail'),
                cellphone: vm.$session.get('memberTel'),
                tel: vm.$session.get('memberTel'),
                address:vm.$session.get('memberCity')+vm.$session.get('memberTown')+vm.$session.get('memberAddress'),
                city:vm.$session.get('memberCity'),
                town:vm.$session.get('memberTown'),
                address_way:vm.$session.get('memberAddress'),
                get_name: vm.receive.name,
                get_email:vm.receive.email,
                get_cellphone: vm.receive.home_phone,
                get_tel: vm.receive.phone,
                get_address: vm.receive.city + vm.receive.town + vm.receive.address,
                pay_mode: vm.pay_check+1,
                pos_mode: vm.how_transport.number,
                pos_super: vm.btn_delivery+1,
                CVSStoreID: vm.map_data.CVSStoreID,
                CVSStoreName: vm.map_data.CVSStoreName,
                CVSAddress: vm.map_data.CVSAddress,
                CVSTelephone: vm.map_data.CVSTelephone,
                pos_money: vm.pos_money,
                unkind:  vm.unkind.checkbox+1,
                unkind_uniform: vm.unkind.uniform,
                unkind_title: vm.unkind.title,
                unkind_vehicle: vm.unkind.vehicle,
                is_use_salecode:1
              }))
              .then(function(response)  {
                    console.log(response.data)
                    if(response.data.status === true &&
                      vm.pay_check+1 == 2){
                      vm.user_main_car = null,
                      vm.$session.remove("shopcar"),
                      vm.$router.push({name:"Finalshopcar", params: {
                        ordernumber:response.data.ordernumber
                      }})
                    }
                    else if (response.data.status === true){
                      (window.location.href = 
                      "/api/ecpay/to_ecpay?ordernumber="+response.data.ordernumber)
                      vm.user_main_car = null,
                      vm.$session.remove("shopcar")
                    }
                    else{
                      alert(response.data.msg)
                    }
              })
            }
            else{
              vm.axios.post('/api/car/addto_order',qs.stringify({
                lang: vm.$store.state.lang,
                m_id: vm.$session.get('memberId'),
                name: vm.$session.get('memberName'),
                email: vm.$session.get('memberEmail'),
                cellphone: vm.$session.get('memberTel'),
                tel: vm.$session.get('memberTel'),
                address:vm.$session.get('memberCity')+vm.$session.get('memberTown')+vm.$session.get('memberAddress'),
                city:vm.$session.get('memberCity'),
                town:vm.$session.get('memberTown'),
                address_way:vm.$session.get('memberAddress'),
                get_name: vm.receive.name,
                get_email:vm.receive.email,
                get_cellphone: vm.receive.home_phone,
                get_tel: vm.receive.phone,
                get_address: vm.receive.city + vm.receive.town + vm.receive.address,
                pay_mode: vm.pay_check+1,
                pos_mode: vm.how_transport.number,
                pos_super: vm.btn_delivery+1,
                CVSStoreID: vm.map_data.CVSStoreID,
                CVSStoreName: vm.map_data.CVSStoreName,
                CVSAddress: vm.map_data.CVSAddress,
                CVSTelephone: vm.map_data.CVSTelephone,
                pos_money: vm.pos_money,
                unkind:  vm.unkind.checkbox+1,
                unkind_uniform: vm.unkind.uniform,
                unkind_title: vm.unkind.title,
                unkind_vehicle: vm.unkind.vehicle,
                
              }))
              .then(function(response)  {
                  console.log(response.data)
                  if(response.data.status === true &&
                    vm.pay_check+1 == 2){
                    vm.user_main_car = null,
                    vm.$session.remove("shopcar"),
                    vm.$store.commit('shopcarItem',null)
                    vm.$router.push({name:"Finalshopcar", 
                    params: {
                      ordernumber:response.data.ordernumber
                    }})
                  }
                  else if (response.data.status === true){
                    (window.location.href = 
                    "/api/ecpay/to_ecpay?ordernumber="+response.data.ordernumber)
                    vm.user_main_car = null,
                    vm.$session.remove("shopcar")
                    vm.$store.commit('shopcarItem',null)
                  }
                  else if (response.data.status === false){
                    alert(response.data.msg)
                  }
              })    
            }
          }
        }
      }
      else{
        alert('請填寫收件人資料必填區塊')
      }

    }
  },
};
</script>

<style>
</style>