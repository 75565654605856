<template>
  <div class="problem">
    <Pagenationtopimg />
    <Pagenationtop :pagenation_title="pagenation_title" path="Problem" />
    <div class="problem-container">
        <template>
            <v-expansion-panels
            v-model="panel"
            multiple
            focusable
            :flat=true
            :tile=true
            >
            <v-expansion-panel
                v-for="(item,i) in items"
                :key="i"
            >
                <v-expansion-panel-header>{{ item.title }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="ps-expansion-container"
                    v-html="item.content">

                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>
        </template>
    </div>
  </div>
</template>

<script>
import Pagenationtopimg from "../components/pagenationimg/index.vue";
import Pagenationtop from "../components/pagenationtop/index.vue";
import qs from 'qs'
export default {
    data(){
        return{
            pagenation_title:[
                '常見問題'
            ],
            panel: [0,1,2,3,4,5],
            items: [
                // {
                //     title:'Q：購買方法',
                //     content:'(1)尋找想要的商品時購物<br>您可以利用所有商品或是輸入關鍵字來搜尋。'
                // },
                // {
                //     title:'Q：付款方式',
                //     content:'可以使用VISA、MASTER和JCB。<br>※關於扣款請參照各信用卡公司的會員規定。詳細內容請向發卡公司詢問。<br>※扣款名目將記載為「ICHIRAN Online Shop」'
                // },
                // {
                //     title:'Q：物流運費',
                //     content:'(1)7-11的賣貨便條款<br>寄件者使用本服務委託配送商品時，其商品完成包裝後之材質及尺寸，須符合以下規定<br>(1)尺寸：最常邊≦45，長+寬+高合計≦105(單位：公分)<br>(2)重量：最長邊<5<br>包裝：自備包材：上、下方需平整的立方體且服務單可完整平貼(服務單尺寸為10*14公分)，門市販售專用包材：交貨便寄件紙箱及交貨便寄件專用袋。'
                // },
                // {
                //     title:'Q：購買方法',
                //     content:'(1)尋找想要的商品時購物<br>您可以利用所有商品或是輸入關鍵字來搜尋。'
                // },
                // {
                //     title:'Q：退換貨服務',
                //     content:'(1)關於商品確認<br>當訂單商品到達後，請在7日內務必確認「商品是否有瑕疵、「訂單內容是否有誤」之狀況。<br>請透過聯絡下列郵件客服人員,將立即為您服務。<br>ichirantw.omiyage@ichiran.com'
                // },
            ]
        }
    },
    created(){
    },
    mounted(){
        this.getData()
    },
    components: {
        Pagenationtopimg,
        Pagenationtop,
    },
    methods:{
        getData(){
            this.axios.post('/api/directions/getqa',qs.stringify({
                lang:this.$store.state.lang
            }))
            .then(response => (
                console.log(response),
                this.items = response.data.result,
                this.$store.commit('closeOverlay')
            ))
            .catch(error => (
                console.log(error),
                this.$store.commit('closeOverlay')
            ))
        }
    }
}
</script>

<style>

</style>