<template>
  <div class="new">
        <Pagenationtopimg />
        <Pagenationtop :pagenation_title="pagenation_title" path="news" />
        <div class="page">
            <h2 class="ps-title">精選</h2>
            <hr class="">
        </div>
        <div class="news-container">
            <v-row>
                <template v-if="main_top_data != null">
                    <v-col
                    cols="12"
                    sm="6"
                    lg="4"
                    v-for="(item) in main_top_data"
                    :key="item.index">
                        <div class="img-container">
                            <img  class="img" :src="'/api/upload/news/'+item.mainid+'/'+item.img" alt="">
                        </div>
                        <p class="ps-title">{{ item.title }}</p>
                        <div class="content-container">
                            <p class="content">{{item.summary }}</p>
                        </div>
                        <div class="date-container">
                            <p class="date">發布日期：{{ item.date }}</p>
                            <v-btn
                            @click="goPage(item.mainid)">
                                了解更多
                            </v-btn>
                        </div>
                    </v-col>
                </template>
                <template v-else>
                    <v-row>
                        <v-col
                        cols="12"
                        sm="6"
                        lg="4"
                        v-for="item in 6"
                        :key="item.index">
                            <v-skeleton-loader
                            width="100%"
                            class="mx-auto"
                            type="card-avatar"
                            ></v-skeleton-loader>
                        </v-col>
                    </v-row>
                </template>
            </v-row>
            <!-- <template>
                <div class="text-center">
                    <v-pagination
                    v-model="top_page"
                    :length="top_length"
                    :total-visible="5"
                    circle
                    color=grey
                    @input="next"
                    ></v-pagination>
                </div>
            </template> -->
        </div>
        <div class="page">
            <h2 class="ps-title ">最新消息</h2>
            <hr class="">
        </div>
        <div class="news-container">
            <v-row>
                <template v-if="main_data != null">
                    <v-col
                    cols="12"
                    sm="6"
                    lg="4"
                    v-for="(item) in main_data"
                    :key="item.index">
                        <div class="img-container">
                            <img  class="img" :src="'/api/upload/news/'+item.mainid+'/'+item.img" alt="">
                        </div>
                        <p class="ps-title">{{ item.title }}</p>
                        <div class="content-container">
                            <p class="content">{{item.summary }}</p>
                        </div>
                        <div class="date-container">
                            <p class="date">發布日期：{{ item.date }}</p>
                            <v-btn
                            @click="goPage(item.mainid)">
                                了解更多
                            </v-btn>
                        </div>
                    </v-col>
                </template>
                <template v-else>
                    <v-row>
                        <v-col
                        cols="12"
                        sm="6"
                        lg="4"
                        v-for="item in 6"
                        :key="item.index">
                            <v-skeleton-loader
                            width="100%"
                            class="mx-auto"
                            type="card-avatar"
                            ></v-skeleton-loader>
                        </v-col>
                    </v-row>
                </template>
            </v-row>
            <template>
                <div class="text-center">
                    <v-pagination
                    v-model="page"
                    :length="length"
                    :total-visible="5"
                    circle
                    color=grey
                    @input="next"
                    ></v-pagination>
                </div>
            </template>
        </div>
  </div>
</template>

<script>
import Pagenationtopimg from "../../components/pagenationimg/index.vue";
import Pagenationtop from "../../components/pagenationtop/index.vue";
export default {
    data(){
        return{ 
            pagenation_title:[
                '最新消息'
            ],
            length:0,
            page:1,
            top_length:0,
            top_page:1,
            language:'tw',
            main_data:null,
            main_top_data:null,
            info:{}
        }
    },
    computed:{
    },
    mounted(){
        this.axios.get('/api/news/getnews',{ 
            params:{lang : this.language , page:this.page},
             })
        .then(response => (
            console.log(response),
                this.main_data = response.data.result,
                // this.main_data = this.main_data.reverse(),
                this.length = response.data.totalpage,
                this.main_top_data = this.main_data.filter(function (item){
                    return item.istop == '1'
                }),
                this.main_top_data = this.main_top_data.reverse(),
                this.$store.commit('closeOverlay')
            ))
        .catch(error => console.log(error))

    },

    components: {
        Pagenationtopimg,
        Pagenationtop,
    },

    methods:{
        next(){
            document.documentElement.scrollTop = 0;
            this.axios.get('/api/news/getnews',{ 
                params:{lang : this.language , page:this.page},
                })
            .then(response => (
                this.main_data = response.data.result
                ))
            .catch(error => console.log(error))
        },
        goPage(mainid){
            this.$router.push({name:`NewDetail` , params: {id:mainid} })
        },
    }
}
</script>

<style>

</style>