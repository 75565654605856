<template>
    <div class="returnproduct">
        <Pagenationtopimg />
        <Pagenationtop :pagenation_title="pagenation_title" path="" />
        <div class="returnproduct-container">
            <v-container
            fluid>
                <v-row>
                    <v-col
                    class="col-head"
                    cols="12">
                        訂購編號：{{this.$route.params.ordernumber}}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    class="col-title"
                    cols="">
                        {{checkout_data.title[0]}}
                    </v-col>
                    <v-col
                    class="col-content"
                    cols="8"
                    md="10">
                            {{checkout_data.content.createdate}}
                    </v-col>
                </v-row>    
                <v-row>
                    <v-col
                    class="col-title"
                    cols="">
                        {{checkout_data.title[1]}}
                    </v-col>
                    <v-col
                    class="col-content"
                    cols="8"
                    md="10">
                        {{checkout_data.content.get_name}}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    class="col-title"
                    cols="">
                        {{checkout_data.title[2]}}
                    </v-col>
                    <v-col
                    class="col-content"
                    cols="8"
                    md="10">
                        {{checkout_data.content.get_address}}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    class="col-title"
                    cols="">
                        {{checkout_data.title[3]}}
                    </v-col>
                    <v-col
                    class="col-content"
                    cols="8"
                    md="10">
                        {{checkout_data.content.get_cellphone}}
                    </v-col>
                </v-row>
                <v-row 
                v-if="this.$session.get('isdealer') != 1">
                    <v-col
                    class="col-title"
                    cols="">
                        {{checkout_data.title[4]}}
                    </v-col>
                    <v-col
                    class="col-content"
                    cols="8"
                    md="10"
                    v-html="checkout_data.content.paymode">
                    </v-col>
                </v-row>
                <v-row
                v-if="this.$session.get('isdealer') != 1">
                    <v-col
                    class="col-title"
                    cols="">
                        {{checkout_data.title[5]}}
                    </v-col>
                    <v-col
                    class="col-content"
                    cols="8"
                    md="10">
                        {{checkout_data.content.invoice_number}}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    class="col-title"
                    cols="">
                        {{checkout_data.title[6]}}
                    </v-col>
                    <v-col
                    class="col-content"
                    cols="8"
                    md="10">
                        <span class="red--text">$
                            {{parseInt(checkout_data.content.totalmoney)+
                            parseInt(checkout_data.content.shipping_fee)-
                            checkout_data.content.discount}}
                        </span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    class="col-title"
                    cols="">
                        {{checkout_data.title[7]}}
                    </v-col>
                    <v-col
                    class="col-content"
                    cols="8"
                    md="10">
                        {{checkout_data.content.order_state}}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    class="col-title"
                    cols="">
                        {{checkout_data.title[8]}}
                    </v-col>
                    <v-col
                    class="col-content"
                    cols="8"
                    md="10">
                        {{checkout_data.content.pos_mode}}
                        <span class="red--text ps-fsz14">{{checkout_data.content.get_address}}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    class="col-head"
                    cols="12">
                        商品明細
                    </v-col>
                </v-row>
                <v-row
                v-for="item in checkout_data.content.detail"
                :key="item.index">
                    <template v-if="item.ispurchase == 1">
                        <v-col
                        class="d-flex col-content border-l"
                        >
                            <div class="img-container">
                                <img :src="'/api/'+item.img" alt="">
                            </div>
                            <div class="content-container">
                                <p>{{ item.products_name }}</p>
                                <p>數量：<span class="black--text">{{item.stock}}</span>
                                </p>
                                <p>價格：<span class="black--text">$</span>
                                    <span class="red--text price">{{ item.price }}</span> 
                                </p>
                            </div>
                        </v-col>
                    </template>
                    <template v-else>
                        <v-col
                        class="d-flex col-content border-l"
                        >
                            <div class="img-container">
                                <img :src="'/api/'+item.img" alt="">
                            </div>
                            <div class="content-container">
                                <p>{{ item.products_name }}</p>
                                <p>顏色：<span class="black--text">{{ item.color_caption}}</span></p>
                                <p>尺寸：<span class="black--text">{{ item.size_caption}}</span></p>
                                <p>數量：<span class="black--text">{{item.stock}}</span>
                                </p>

                                <p>價格：<span class="black--text">$</span>
                                    <span class="red--text price">{{ item.price }}</span> 
                                </p>
                            </div>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
            <v-container class="blank">
                <v-row >
                    <v-col
                    class="col-head"
                    cols="2">
                        匯款帳號
                    </v-col>
                    <v-col
                    class="col-head">
                    <span class="tx-red">
                        ＊我們會將款項退還至指定帳戶，如使用信用卡付款則是原卡退刷。
                    </span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    class="col-content col-content-title"
                    cols="2">
                        銀行代號
                    </v-col>
                    <v-col
                    class="col-content">
                    <input type="text"
                    v-model="user.blank_code"
                    placeholder="請輸入銀行代碼">
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    class="col-content col-content-title"
                    cols="2">
                        銀行帳號
                    </v-col>
                    <v-col
                    class="col-content">
                    <input type="text"
                    v-model="user.blank_account"
                    placeholder="請輸入銀行帳號">
                    </v-col>
                </v-row>
            </v-container>

            <v-container class="blank">
                <v-row >
                    <v-col
                    class="col-head">
                        <span class="tx-red">*</span>退貨說明
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    class="col-content">
                    <textarea
                    v-model="user.return_content"
                    placeholder="請輸入退貨原因"
                    name="" id="" cols="30" rows="10"></textarea>
                    </v-col>
                </v-row>
            </v-container>

            <v-container class="blank">
                <v-row >
                    <v-col
                    class="col-head">瑕疵商品照片
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    class="col-content">
                        <button @click="$refs.file.click()">
                            上傳
                        </button>
                        <template v-if="preview_list.length">
                            <div v-for="item, index in preview_list" :key="index">
                                <div class="product-img-container">
                                    <img :src="item" class="img-fluid" />
                                    <p class="mb-0">檔案名稱: {{ image_list[index].name }}</p>
                                </div>
                            </div>
                        </template>
                    </v-col>
                </v-row>
            </v-container>

            <div class="btn-container">
                <input 
                accept="image/*"
                class="button hide"
                ref="file" 
                type="file" 
                @change="previewImage" >
                <button @click="onSubmit">
                    送出
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Pagenationtopimg from "../../components/pagenationimg/index.vue";
import Pagenationtop from "../../components/pagenationtop/index.vue";
import qs from 'qs'
export default {
    data(){
        return{      
            preview_list: [],
            image_list: [],
            pagenation_title:[
                '訂單明細',
                '退貨申請'
            ],
            user:{
                blank_code:'',
                blank_account:'',
                return_content:'',  
            },
            checkout_data:{
                title:[
                    '訂購日期',
                    '收件人',
                    '送貨地址',
                    '手機號碼',
                    '付款方式',
                    '電子發票',
                    '總金額',
                    '訂單狀態',
                    '運送方式'
                ],
                content:[]
            },
        }
    },
    components: {
        Pagenationtopimg,
        Pagenationtop,
    },
    mounted(){
        this.getOrder()
    },
    methods:{
        //顯示圖片
        previewImage: function(event) {
            var input = event.target;
            var count = input.files.length;
            var index = 0;
            if (input.files) {
                while(count --) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.preview_list.push(e.target.result);
                }
                this.image_list.push(input.files[index]);
                reader.readAsDataURL(input.files[index]);
                index ++;
                }
            }
        },
        //傳送圖片
        onSubmit() {
            this.axios.post('/api/member/return_goods',qs.stringify({
                summary: this.user.return_content ,//退貨原因
                ordernumber: this.$route.params.ordernumber , //訂單編號
                order_name : this.checkout_data.content.get_name , //收件人
                order_tel : this.checkout_data.content.get_cellphone , //電話
                order_address: this.checkout_data.content.get_address , //地址
                updatedate: this.checkout_data.content.createdate , //新增日期
                totalmoney: this.checkout_data.content.totalmoney , //總金額
                shipping_fee: this.checkout_data.content.shipping_fee ,//運費
                discount : this.checkout_data.content.discount ,//折扣
                bankcodename: this.user.blank_code, //銀行代號
                bankacc: this.user.blank_account , //銀行帳號
                img_list: this.preview_list ,//照片
            }))
            .then( res => (
                console.log(res),
                this.$store.commit("closeOverlay"),
                this.$router.push({name:`MemberCenter`,
                params:{
                    id: 2 ,
                }})
            ))
            console.log(this.preview_list);
        },
        getOrder(){
            this.axios.post('/api/car/get_order_detail',qs.stringify({
                ordernumber: this.$route.params.ordernumber,
                m_id: this.$session.get('memberId'),
                isdealer: this.$session.get('isdealer')
            }))
            .then(response => (
                console.log(response),
                this.checkout_data.content = response.data.data,
                this.$store.commit("closeOverlay")
            ))
        },
        goIndex(){
            this.$router.push('/')
        }
    }
}
</script>

<style>

</style>