<template>
  <div class="shoppingscratch">
        <Pagenationtopimg />
        <fixedshop />
        
        <v-container class="mt-16">
            
            <p class="ps-title">搜尋結果(
                <span v-if="total_product">{{total_product}}</span>  
                <span v-else>0</span>  
            )：</p>
            <v-row v-if="total_product">
                <v-slide-x-reverse-transition group class="transition">
                    <v-col
                    cols="6"
                    md="4"
                    lg="3"
                    v-for="(item,item_index) in product_data"
                    @click="router(item.mainid)"
                    :key="item_index">
                        <template>
                            <div class="img-container">
                                <div class="self-container"
                                v-if="item.self != null">
                                    {{item.self}}
                                </div>
                                <img 
                                lazy-src="https://picsum.photos/id/11/10/6"
                                class="main-img" :src='"/api/"+item.img' alt="">
                                <div class="icon-container">
                                    <div class="icon-sub-container"
                                    @click.stop="addInsertCollect(item.id)">
                                        <img class="love" src="../../../public/img/product-love.png" alt="">
                                    </div>
                                    <div class="icon-sub-container">
                                        <img class="love" src="../../../public/img/product-shop.png" alt="">
                                    </div>
                                </div>
                            </div>
                            <p>{{item.title}}</p>
                            <div class="content">
                                <p>{{item.summary}}</p>
                            </div>
                            <p>$
                                    {{item.price}}
                            </p>
                        </template>
                    </v-col>
                </v-slide-x-reverse-transition>
            </v-row>
            <div v-else class="noscratch">
                <div class="noscratch-container">
                找不到符合搜尋字詞「<span class="red--text">{{ this.$route.params.scratch }}</span>」的產品。
                </div>
                <div class="btn-container">
                    <v-btn @click="goIndex">
                        回首頁
                    </v-btn>
                </div>
            </div>
        </v-container>

        <template v-if="total_product">
            <div class="text-center">
                <v-pagination
                v-model="totalpage"
                :length="totalpage"
                :total-visible="7"
                circle
                color=grey
                ></v-pagination>
            </div>
        </template>
  </div>
</template>

<script>
import Pagenationtopimg from "../../components/pagenationimg/index.vue";
import fixedshop from '../../components/fixedshop/index.vue'
import qs from 'qs'
export default {
    data(){
        return{
            product_data:null,
            totalpage:null,
            total_product:null,
        }
    },
    components: {
        Pagenationtopimg,
        fixedshop
    },
    created(){
        this.$store.commit('openOverlay')
        this.getData()
    },
    methods:{
        //產品列表
        getData(){
            this.axios.post('/api/products/get_products_list', qs.stringify({
                lang: this.$store.state.lang,
                all_search: this.$route.params.scratch,
            }))
            .then(responese => (
                console.log(responese),
                this.product_data = responese.data.result,
                this.totalpage = responese.data.totalpage,
                this.total_product = responese.data.recordcount,
                this.$store.commit('closeOverlay')
            ))
        },
        goIndex(){
            this.$router.push('./')
        },
        router(mainid){
            this.$router.push({name:`Productview` , 
            params: {mainid:mainid }})
        },
        addInsertCollect(id){
            this.$store.commit('openOverlay')
            if(this.$session.get('memberLogin')){
                this.axios.post('/api/products/insert_collect', qs.stringify({
                    lang: this.$store.state.lang,
                    p_id: id,
                    m_id: this.$session.get('memberId'),
                    ismain: 1
                }))
                .then(response => (
                    console.log(response),
                    alert(response.data.msg),
                    this.$store.commit('closeOverlay')
                ))
            }
            else{
                alert('請先登入會員')
                this.$store.commit('activeLogin')
            }

        },
    }
}
</script>

<style>

</style>