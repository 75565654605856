<template>
  <div class="subview">
    <Pagenationtopimg/>
    <Pagenationtop :pagenation_title='pagenation_title' path='Subview'/>
    <div class="subview-container">
        <h2>
            獲得EXUSTAR的最新消息與優惠資訊
        </h2>
        <h2>
            訂閱電子報就能收到來自EXUSTAR品牌的最新消息和專屬優惠
        </h2>
        <v-container>
            <v-row>
                <v-col
                cols="3"
                class="col-title">
                    <span class="red--text">*</span>
                    電子信箱
                </v-col>
                <v-col
                cols="9"
                class="col-content">
                    <input v-model="user.email" type="text" name="email" placeholder="請輸入電子信箱">
                </v-col>
            </v-row>
            <v-row>
                <v-col
                cols="3"
                class="col-title">
                    <span class="red--text">*</span>
                    驗證碼
                </v-col>
                <v-col
                cols="9"
                class="col-content">
                    <input v-model="user.acode" type="text" name="number" placeholder="請輸入4碼驗證碼">
                    <div class="sub-container">
                        <span class="number">
                            <img :src="captcha_img" alt="">
                        </span>
                        <button>
                            <img src="../../public/img/creatmember/replay.png" alt="">
                        </button>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <div class="button-container">
            <v-btn
            @click="postData">
                訂閱
            </v-btn>
        </div>
    </div>
  </div>
</template>

<script>
import Pagenationtopimg from "../components/pagenationimg/index.vue";
import Pagenationtop from '../components/pagenationtop/index.vue'
import qs from 'qs'
export default {
    data(){
        return{
            captcha_img:null,
            pagenation_title:[
                '訂閱最新消息'
            ],
            user:{
                email:null,
                acode:null,
            },
            info:null
        }
    },
    components:{
        Pagenationtopimg,
        Pagenationtop,
    },
    mounted(){
        this.getCaptcha()
        this.$store.commit('closeOverlay')
    },
    methods:{
        getCaptcha(){
            this.axios.post('/api/member/captcha_show')
            .then(response => (
                this.captcha_img = response.data.message,
                this.info = response.data))
            .catch(function (error){
                console.log(error)
            })
        },
        postData(){
            this.axios.post('/api/contact/to_mail_send', qs.stringify({
                lang:this.$store.state.lang,
                email:this.user.email,
                acode:this.user.acode,
            }))
            .then(response => (
                this.info = response,
                alert(this.info.data.msg)
            ))
            .catch(error => (
                console.log(error)
            ))
        }
    }
}
</script>

<style>

</style>