<template>
  <div class="customerservice">
    <Pagenationtopimg />
    <Pagenationtop :pagenation_title="pagenation_title" path="Customerservice" />
    <div class="customerserveic-container">
        <div class="content-container">
            <div class="left">
                <p>
                    告訴我們您對本站的任何產品或其他事情的意見或需求，我們很歡迎您的來信。
                </p>
                <p>標註 <span class="red--text">[ ＊ ]</span> 為必填項目
                </p>
            </div>
            <div class="right">
                <v-container fluid>
                    <v-row>
                        <v-col
                        cols="3"
                        sm=2
                        class="col-title">
                           <span class="red--text">*</span> 聯絡人
                        </v-col>
                        <v-col
                        cols="9"
                        sm=10
                        class="col-content">
                            <input v-model="user.name" type="text" name="name" placeholder="請輸入姓名">
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                        cols="3"
                        sm="2"
                        class="col-title">
                            性別
                        </v-col>
                        <v-col
                        cols="9"
                        sm="10"
                        class="col-content">
                            <v-radio-group v-model="user.sex">
                            <v-radio
                                color="black"
                                label="男生"
                                value="男生"
                            ></v-radio>
                            <v-radio
                                color="black"
                                label="女生"
                                value="女生"
                            ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                        cols="3"
                        sm="2"
                        class="col-title">
                            連絡電話
                        </v-col>
                        <v-col
                        cols="9"
                        sm="10"
                        class="col-content">
                            <input v-model="user.tel"  type="text" name="phone" placeholder="請輸入市話或手機(例:0911999999、04-1234567)">
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                        cols="3"
                        sm="2"
                        class="col-title">
                            <span class="red--text">*</span>電子信箱
                        </v-col>
                        <v-col
                        cols="9"
                        sm="10"
                        class="col-content">
                            <input v-model="user.email"  type="text" name="email" placeholder="請輸入信箱">
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                        cols="3"
                        sm="2"
                        class="col-title">
                            <span class="red--text">*</span>內容
                        </v-col>
                        <v-col
                        cols="9"
                        sm="10"
                        class="col-content">
                            <textarea v-model="user.content"  id="w3review" name="w3review" rows="4" cols="50"
                            placeholder="請輸入內容">
                            </textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                        cols="3"
                        sm="2"
                        class="col-title">
                            <span class="red--text">*</span>驗證碼
                        </v-col>
                        <v-col
                        cols="9"
                        sm="10"
                        class="col-content number-container">
                            <input v-model="user.acode"  type="text" name="name" placeholder="請輸入4碼驗證碼">
                            <div class="sub-container">
                                <span class="number">
                                    <img :src="captcha_img" alt="">
                                </span>
                                <button>
                                    <img src="../../public/img/creatmember/replay.png" alt="">
                                </button>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
                <div class="button-container">
                    <v-btn
                    @click="clearInput">
                        重填
                    </v-btn>
                    <v-btn
                    @click="postData">
                        送出
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Pagenationtopimg from "../components/pagenationimg/index.vue";
import Pagenationtop from "../components/pagenationtop/index.vue";
import qs from 'qs'
export default {
    data(){
        return{
            pagenation_title:[
                '客服中心'
            ],
            captcha_img:null,
            radioGroup:1,
            user:{
                name:null,
                sex:null,
                tel:null,
                email:null,
                content:null,
                acode:null
            },
            info:null
        }
    },
    mounted(){
        this.getCaptcha()
    },
    components: {
        Pagenationtopimg,
        Pagenationtop,
    },
    methods:{
        clearInput(){
            this.user.name = null
            this.user.sex = null
            this.user.tel = null
            this.user.email = null
            this.user.content = null
            this.user.acode = null
        },
        getCaptcha(){
        this.axios.post('/api/member/captcha_show')
        .then(response => (
            this.captcha_img = response.data.message,
            this.info = response.data))
        .catch(function (error){
            console.log(error)
        })
        },
        postData(){
            this.axios.post('/api/contact/send', qs.stringify({
                lang:this.$store.state.lang,
                name:this.user.name,
                sex:this.user.sex,
                tel:this.user.tel,
                email:this.user.email,
                content:this.user.content,
                acode:this.user.acode
            }))
            .then(response => (
                this.info = response,
                alert(this.info.data.msg)
            ))
            .catch(error =>(
                console.log(error)
            ))
        }
    }
}
</script>

<style>

</style>