<template>
  <div class="membercenter">
    <Pagenationtopimg />
    <Pagenationtop :pagenation_title="pagenation_title" path="MemberCenter" />
    <div class="main-container">
      <div class="left">
        <div class="ps-title">會員中心</div>
        <div class="content-container">
          <div class="content"
          v-for="(item,item_index) in side_data"
          :key="item.index"
          :class="{active: activeindex === item_index}"
          @click="sideTo(item_index)">{{ item }}</div>
        </div>
      </div>
      <template
      v-if ="activeindex === 0">
        <div class="right">
          <div class="ps-title">會員資料</div>
          <hr />
          <div class="content-container">
            <div class="content between">
              <span class="sub-title w-35" 
              v-if="this.$session.get('isdealer') != 1">
                  <span class="tx-red">*</span>
                  會員編號
              </span>
              <span class="sub-title w-35" 
              v-else>
                  <span class="tx-red">*</span>
                  客戶代號
              </span>
              <label
              v-if="this.$session.get('isdealer') != 1"
              >{{member_data.numbering}}</label>
              <label
              v-else
              >{{member_data.account}}</label>
            </div>
            <div class="content">
              <span class="sub-title w-35"
              v-if="this.$session.get('isdealer') != 1" >
                  <span class="tx-red">*</span>
                  註冊手機
              </span>
              <span class="sub-title w-35"
              v-else>
                  <span class="tx-red">*</span>
                  統一編號
              </span>
              
              <label
              v-if="this.$session.get('isdealer') != 1"
              >{{member_data.account}}</label>
              <label v-else>{{member_data.numbering}}</label>
            </div>
            <div class="content connection">
              <span class="sub-title " >
                  <span class="tx-red">*</span>
              聯絡人</span>
              <input v-model="member_data.name"/>
            </div>
            <div class="content radio"
            v-if="this.$session.get('isdealer') != 1">
              <span class="sub-title ">性別</span>
              <div class="radio-container">
                <span>
                  <input type="radio" v-model="member_data.sex" id="boy" value="男生" />
                  男生</span
                >
                <span>
                  <input
                    type="radio"
                    v-model="member_data.sex"
                    id="girl"
                    value="女生"
                  />女生</span
                >
              </div>
            </div>
            <div class="content birthday mb-1"
            v-if="this.$session.get('isdealer') != 1">
              <span class="sub-title ">
                  <span class="tx-red ">*</span>
                  生日
                  </span>
                  <div>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="member_data.birthday"
                          label="Birthday date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          color="grey darken-3"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="zh-cn"
                        :disabled="disabled_birthday"
                        color="grey darken-1"
                        v-model="member_data.birthday"
                        :active-picker.sync="activePicker"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        min="1950-01-01"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                  </div>
            </div>
            <div class="content connection">
              <span class="sub-title "><span class="tx-red">*</span>聯絡電話</span>
              <input
              class="input"
                type="text"
                name="phone"
                v-model="member_data.tel"
              />
            </div>
            <div class="content connection"
              v-if="this.$session.get('isdealer') == 1">
              <span class="sub-title "><span class="tx-red">*</span>行動電話</span>
              <input
                class="input"
                type="text"
                name="mobile_phone"
                v-model="member_data.mobile_tel"
              />
            </div>
            <div class="content">
              <span class="sub-title ">
                  <span class="tx-red">*</span>
                  電子信箱</span>
              <input class="input" type="text" v-model="member_data.email"/>
            </div>
            <div class="content address">
              <span class="sub-title ">
                <span class="tx-red">*</span>
                聯絡地址
              </span>
              <div class="select-container">
                <v-select
                  :items="cityname"
                  menu-props="auto"
                  v-model="member_data.city"
                  label="請選擇"
                  dense
                  outlined
                ></v-select>
                <v-select
                  :items="townname"
                  menu-props="auto"
                  v-model="member_data.town"
                  label="請選擇"
                  dense
                  outlined
                ></v-select>
              </div>
              <input class="input" type="text" v-model="member_data.address"/>
            </div>
            <div class="content number">
              <span class="sub-title ">
              <span class="tx-red">*</span>
              驗證碼</span>
              <div class="sub-container">
                <input v-model="member_data.acode" type="text" class="input" placeholder="請輸入4碼驗證碼">
                <div class="icon-container">
                  <div class="number">
                    <img :src="captcha_img" alt="">
                  </div>
                  <button @click="getCaptcha">
                    <img src="../../../public/img/creatmember/replay.png" alt="">
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="button-container">
            <button @click="clear">
              取消
            </button>
            <button
            @click="editMemberData">
              確認修改
            </button>
          </div>
        </div>   
      </template>
      <template
      v-if ="activeindex === 1">
        <div class="right">
          <div class="ps-title">修改密碼</div>
          <hr>
          <div class="password-container">
            <div class="content">
              <span class="sub-title"><span class="red--text">*</span>輸入舊密碼</span>
              <div class="password-container"
              v-if="password_eye">
                  <input class="input" v-model="edit_password.password_old" type="text" @keyup.enter="Login" placeholder="請輸入舊密碼">
                  <i @click="eyeStatus" class="fas fa-eye"></i>
              </div>
              <div class="password-container" 
              v-else>
                  <input class="input" v-model="edit_password.password_old" type="password" @keyup.enter="Login" placeholder="請輸入舊密碼">
                  <i @click="eyeStatus" class="fas fa-eye-slash"></i>
              </div>
            </div>
            <div class="content">
              <span class="sub-title"><span class="red--text">*</span>新密碼</span>
              <div class="password-container"
              v-if="password_eye">
                  <input class="input" v-model="edit_password.password" type="text" @keyup.enter="Login" placeholder="請輸入6-20碼英數字">
                  <i @click="eyeStatus" class="fas fa-eye"></i>
              </div>
              <div class="password-container" 
              v-else>
                  <input class="input" v-model="edit_password.password" type="password" @keyup.enter="Login" placeholder="請輸入6-20碼英數字">
                  <i @click="eyeStatus" class="fas fa-eye-slash"></i>
              </div>
            </div>
            <div class="content">
              <span class="sub-title"><span class="red--text">*</span>確認新密碼</span>
              <div class="password-container"
              v-if="password_eye">
                  <input class="input" v-model="edit_password.password2" type="text" @keyup.enter="Login" placeholder="請輸入6-20碼英數字">
                  <i @click="eyeStatus" class="fas fa-eye"></i>
              </div>
              <div class="password-container" 
              v-else>
                  <input class="input" v-model="edit_password.password2" type="password" @keyup.enter="Login" placeholder="請輸入6-20碼英數字">
                  <i @click="eyeStatus" class="fas fa-eye-slash"></i>
              </div>
            </div>
            <div class="content">
              <span class="sub-title"><span class="red--text">*</span>驗證碼</span>
              <input v-model="edit_password.acode" class="input" type="text" placeholder="請輸入4碼驗證碼">
              <div class="icon-container">
                <div class="number">
                  <img :src="captcha_img" alt="">
                </div>
                <button @click="getCaptcha">
                  <img src="../../../public/img/creatmember/replay.png" alt="">
                </button>
              </div>
            </div>
          </div>
          <div class="button-container">
            <button @click="clear">
              取消
            </button>
            <button
            @click="editPassword">
              確認修改
            </button>
          </div>
        </div>
      </template>
      <template
      v-if ="activeindex === 2">
        <div class="right">
          <div class="ps-title">歷史訂單</div>
          <hr>
          <div class="history-container">
            <div class="top-content">
              <div class="content">
                <span class="sub-title">訂單編號</span>
                <div class="sub-content">
                  <input class="input" type="text" v-model="search_order" 
                  @keyup.enter='getSearchOrderList(search_order)'
                  placeholder="請輸入訂單編號">
                  <button @click="getSearchOrderList(search_order)">查詢</button>
                </div>
              </div>
              <div class="sub-content-2">
                <div class="canvas"></div>
                <p>系統只保留90天內的訂單提供查詢</p>
              </div>  
                <button @click="getOrderList">查看90天內所有訂單</button>
            </div>
            <hr>
            <div class="ps-sub-title">訂單明細</div>
              <v-expansion-panels 
                v-model="panel"
                focusable
                multiple
                v-if="this.$session.get('isdealer') != 1">
                <v-expansion-panel
                  v-for="(item,i) in get_order_list"
                  :key="i"
                >
                  <v-expansion-panel-header
                  @click="iconChang(i)">
                    <template v-slot:actions>
                      <v-icon :class="{cheng:icon_chang}" v-text="v_text[i]">

                      </v-icon>
                    </template>
                    訂單編號：{{ item.ordernumber }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                  id="innerExPan"
                  eager>
                    <v-container fluid>
                      <v-row>
                        <v-col class="grey lighten-4">
                          {{ order[0].list[0].title }}
                        </v-col>
                        <v-col
                        cols="9"
                        class="content">
                          {{item.createdate}}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="grey lighten-4">
                          {{ order[0].list[1].title }}
                        </v-col>
                        <v-col
                        cols="9"
                        class="content">
                          {{item.get_name}}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="grey lighten-4">
                          {{ order[0].list[2].title }}
                        </v-col>
                        <v-col
                        cols="9"
                        class="content">
                          {{item.get_address}}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="grey lighten-4">
                          {{ order[0].list[3].title }}
                        </v-col>
                        <v-col
                        cols="9"
                        class="content">
                          {{item.get_cellphone}}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="grey lighten-4">
                          {{ order[0].list[4].title }}
                        </v-col>
                        <v-col
                        cols="9"
                        class="content"
                        v-html="item.paymode">
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="grey lighten-4">
                          {{ order[0].list[5].title }}
                        </v-col>
                        <v-col
                        cols="9"
                        class="content">
                          {{item.invoice_number}}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="grey lighten-4">
                          {{ order[0].list[6].title }}
                        </v-col>
                        <v-col
                        cols="9"
                        class="content">
                          $ 
                          <span class="red--text">
                            {{(parseInt(item.totalmoney) + 
                            parseInt(item.shipping_fee) - 
                            item.discount).toString().replace(/\B(?=(\d{3})+$)/g,',')}}
                          </span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="grey lighten-4">
                          {{ order[0].list[7].title }}
                        </v-col>
                        <v-col
                        cols="9"
                        class="content order-status">
                          {{item.order_state}}
                          <button 
                          :disabled="item.can_return == '0'"
                          @click="deleteOrderApi(item.ordernumber)">
                            訂單取消
                          </button>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="grey lighten-4">
                          {{ order[0].list[8].title }}
                        </v-col>
                        <v-col
                        cols="9"
                        class="content">
                          {{item.posmode}}
                         <span class="red--text body-2" >
                            ({{item.get_address}})
                          </span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="grey lighten-4">
                          {{ order[0].list[9].title }}
                        </v-col>
                        <v-col
                        cols="9"
                        class="content">
                          <button
                          :disabled="item.ship_state == '1' || item.can_return == '1'  || item.is_cancel == '1'"
                          @click="goReturnProduct(item.ordernumber)">
                            退貨
                          </button>
                        </v-col>
                      </v-row>
                    </v-container>
                    <div class="sub-title-container ">
                      <h3>商品明細</h3>
                    </div>
                    <v-container>
                      <v-row   
                      class="content"                   
                      v-for = " list in item.detail"
                      :key="list.index">
                        <template v-if="list.ispurchase == 1">
                          <v-col>
                            <div class="img-container">
                              <img :src="'api/'+list.img" alt="">
                            </div>
                          </v-col>
                          <v-col
                          class="product-content"
                          cols="9">
                            <p>
                              {{ list.products_name }}
                            </p>
                            <p>數量：{{ list.stock}}</p>
                            <p>價格：$<span class="red--text">{{ list.price.toString().replace(/\B(?=(\d{3})+$)/g,',')}}</span></p>     
                          </v-col>
                        </template>
                        <template v-else>
                          <v-col>
                            <div class="img-container">
                              <img :src="'/api/'+list.img" alt="">
                            </div>
                          </v-col>
                          <v-col
                          class="product-content"
                          cols="9">
                            <p>
                              {{ list.products_name }}
                            </p>
                            <p>尺寸：{{list.size_caption}}</p>
                            <p>顏色：{{ list.color_caption}}</p>
                            <p>數量：{{ list.stock}}</p>
                            <p>價格：$<span class="red--text">{{ list.price.toString().replace(/\B(?=(\d{3})+$)/g,',')}}</span></p>     
                          </v-col>
                        </template>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels 
                focusable
                v-model="panel"
                multiple
                v-else>
                <v-expansion-panel
                  v-for="(item,i) in get_order_list"
                  :key="i"
                >
                  <v-expansion-panel-header
                  @click="iconChang(i)">
                    <template v-slot:actions>
                      <v-icon :class="{cheng:icon_chang}" v-text="v_text[i]">
                        
                      </v-icon>
                    </template>
                    訂單編號：{{ item.ordernumber }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                  id="innerExPan"
                  eager>
                    <v-container fluid>
                      <v-row>
                        <v-col class="grey lighten-4">
                          {{ order[0].list[0].title }}
                        </v-col>
                        <v-col
                        cols="9"
                        class="content">
                          {{item.createdate}}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="grey lighten-4">
                          {{ order[0].list[1].title }}
                        </v-col>
                        <v-col
                        cols="9"
                        class="content">
                          {{item.get_name}}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="grey lighten-4">
                          {{ order[0].list[2].title }}
                        </v-col>
                        <v-col
                        cols="9"
                        class="content">
                          {{item.get_address}}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="grey lighten-4">
                          {{ order[0].list[3].title }}
                        </v-col>
                        <v-col
                        cols="9"
                        class="content">
                          {{item.get_cellphone}}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="grey lighten-4">
                          {{ order[0].list[6].title }}
                        </v-col>
                        <v-col
                        cols="9"
                        class="content">
                          $ 
                          <span class="red--text">
                            {{(parseInt(item.totalmoney) + 
                            parseInt(item.shipping_fee) - 
                            item.discount).toString().replace(/\B(?=(\d{3})+$)/g,',')}}
                          </span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="grey lighten-4">
                          {{ order[0].list[7].title }}
                        </v-col>
                        <v-col
                        cols="9"
                        class="content">
                          {{item.order_state}}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="grey lighten-4">
                          {{ order[0].list[8].title }}
                        </v-col>
                        <v-col
                        cols="9"
                        class="content">
                          {{item.posmode}}
                         <span class="red--text body-2" >
                            ({{item.get_address}})
                          </span>
                        </v-col>
                      </v-row>
                      
                      <v-row>
                        <v-col class="grey lighten-4">
                          {{ order[0].list[9].title }}
                        </v-col>
                        <v-col
                        cols="9"
                        class="content">
                          <button
                          :disabled="item.ship_state == '1' || item.is_cancel == '1'" 
                          @click="goReturnProduct(item.ordernumber)">
                            退貨
                          </button>
                        </v-col>
                      </v-row>
                    </v-container>
                    <div class="sub-title-container ">
                      <h3>商品明細</h3>
                    </div>
                    <v-container>
                      <v-row   
                      class="content"                   
                      v-for = " list in item.detail"
                      :key="list.index">
                        <template v-if="list.ispurchase == 1">
                          <v-col>
                            <div class="img-container">
                              <img :src="'api/'+list.img" alt="">
                            </div>
                          </v-col>
                          <v-col
                          class="product-content"
                          cols="9">
                            <p>
                              {{ list.products_name }}
                            </p>
                            <p>數量：{{ list.stock}}</p>
                            <p>價格：$<span class="red--text">{{ list.price.toString().replace(/\B(?=(\d{3})+$)/g,',')}}</span></p>     
                          </v-col>
                        </template>
                        <template v-else>
                          <v-col>
                            <div class="img-container">
                              <img :src="'/api/'+list.img" alt="">
                            </div>
                          </v-col>
                          <v-col
                          class="product-content"
                          cols="9">
                            <p>
                              {{ list.products_name }}
                            </p>
                            <p>尺寸：{{list.size_caption}}</p>
                            <p>顏色：{{ list.color_caption}}</p>
                            <p>數量：{{ list.stock}}</p>
                            <p>價格：$<span class="red--text">{{ list.price.toString().replace(/\B(?=(\d{3})+$)/g,',')}}</span></p>     
                          </v-col>
                        </template>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
          </div>
        </div>

      </template>
      <template
      v-if ="activeindex === 3">
        <div class="right">
          <div class="ps-title">我的折價券</div>
          <hr>
          <div class="self-container" v-if="get_coupon_list">
              <div class="content"
              v-for="item in get_coupon_list"
              :key="item.index"
              :class="{active:item.isuse == 0}">
                <div class="img-container">
                  <img v-if="item.isuse == 0" :src='self_data[0].img' alt="">
                  <img v-else :src='self_data[0].img_use' alt="">
                  <div class="title-container">
                    <p class="main-title">折價券</p>
                    <p class="sub-title">coupan</p>
                  </div>
                </div>
                <div class="sub-container">
                  <p>{{ item.cpation }}</p>
                  <p class="serial">使用序號：<span>{{ item.code }}</span></p>
                  <p class="date" v-if="item.isuse == 0">
                    {{ item.use_sdate }}-
                    <span v-if="item.use_edate != null">{{item.use_edate}} 到期</span>
                    <span v-else>無期限</span>
                  </p>
                  <p class="date use" v-else-if="item.isuse == 1">
                    已使用
                  </p>
                  <p class="date use" v-else>
                    已過期
                  </p>
                </div>
              </div>
          </div>
          <div v-else>
            <div class="text-center mt-10" style="font-size:24px">您目前沒有折價券</div>
          </div>
        </div>

      </template>
      <template
      v-if ="activeindex === 4">
        <div class="right">
          <div class="ps-title">我的收藏</div>
          <div class="collect" v-if="collect_list != undefined">
            <div class="collect-container"
            v-for="(item,i) in collect_list"
            :key="item.index">
              <div class="left">
                <div class="img-container">
                  <img :src="'/api/'+item.data.img" alt="">
                </div>
                <div class="content">
                  <p>
                    {{ item.data.title }}
                  </p>
                  <p>顏色：{{ item.data.color_caption}}</p>
                  <p>價格：$<span class="red--text">{{ item.data.price}}</span></p>  
                </div>
              </div>
              <div class="right">
                <div class="top">
                  <div class="top-container">
                    狀態
                  </div>
                  <div class="top-container">
                    最新通知紀錄
                  </div>
                  <div class="top-container">
                    刪除
                  </div>
                </div>
                <div class="bottom">
                  <div class="bottom-container">
                    <v-btn class="add-shop" 
                    @click="routerProduct(
                      item.data.mainid)"
                    v-if="item.ismain == 1" 
                    depressed>
                      <span>
                        <img src="../../../public/img/nav_icon-3.png" alt="">
                      </span>
                      選擇尺寸
                    </v-btn>
                    <v-btn class="add-shop" 
                    @click="addShopCar(
                      item.data.mainid,
                      item.data.color_mainid,
                      item.data.size_mainid)"
                    v-else-if="item.data.stock > 0" 
                    depressed>
                      <span>
                        <img src="../../../public/img/nav_icon-3.png" alt="">
                      </span>
                      加入購物車
                    </v-btn>
                    <v-btn class="not-work" 
                    v-else >
                      售完，補貨中
                    </v-btn>
                  </div>
                  <div class="bottom-container">
                    {{ item.data.notice }}
                  </div>
                  <div class="bottom-container">
                    <img @click="deleteCollect(i,item.id)" src="../../../public/img/membercenter/trash.png" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="nocollect" v-else>
            <hr>
            <div class="nocollect-container">
              <div>您目前沒有收藏品</div>
              <v-btn @click="goShoppingCenter">購物</v-btn>
            </div>
          </div>
        </div>

      </template>
    </div>
  </div>
</template>

<script>
import Pagenationtopimg from "../../components/pagenationimg/index.vue";
import Pagenationtop from "../../components/pagenationtop/index.vue";
import cityName_data from "@/assets/citydata.json";
import qs from 'qs'
export default {
  data() {
    return {
      panel:[0], //歷史訂單預設展開第一
      password_eye:false,//密碼顯示
      collect_list:null,
      get_coupon_list:null,
      search_order:null,
      cityname: [],
      townname:[],
      info:null,
      activeindex:0,
      picked: "boy",
      activePicker: null,
      menu: false,
      icon_chang: false,
      captcha_img:null,
      disabled_birthday:false,
      pagenation_title:[
        '會員中心'
      ],
      date:null,
      member_data:{
        mobile_tel:this.$session.get('memberPhone'),
        name: this.$session.get('memberName'),
        numbering: this.$session.get('memberNumbering'),
        account: this.$session.get('memberAccount'),
        birthday: this.$session.get('memberBirthday'),
        tel: this.$session.get('memberTel'),
        email: this.$session.get('memberEmail'),
        city: this.$session.get('memberCity'),
        town: this.$session.get('memberTown'),
        address: this.$session.get('memberAddress'),
        acode: null,
        sex:this.$session.get('memberSex'),
      },
      get_order_list:null,
      edit_password:{
        password_old:null,
        password:null,
        password2:null,
        acode:null,
      },
      v_text:[
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
        'mdi-plus-circle',
      ],
    };
  },
  computed:{
    side_data(){
      return [
        '會員資料',
        '修改密碼',
        '歷史訂單',
        '我的折價券',
        '我的收藏'
      ]
    },
    order(){
      return[
        {
          list:[
            {
              title:'訂購日期',
            },
            {
              title:'收件人',
            },
            {
              title:'送貨地址',
            },
            {
              title:'手機號碼',
            },
            {
              title:'付款方式',
            },
            {
              title:'電子發票',
            },
            {
              title:'總金額',
            },
            {
              title:'訂單狀態',
            },
            {
              title:'運送方式',
            },
            {
              title:'退貨處理',
            }
          ],
        },
      ]
    },
    ///self

    self_data(){
      return[
        {
          img:require('../../../public/img/membercenter/self_paper-1.png'),
          img_use:require('../../../public/img/membercenter/self_paper_gray.png'),
        },
      ]
    },
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    "member_data.city": function() {
      this.townname.splice(0,this.townname.length)
      console.log(this.townname)
      let index = this.cityname.indexOf(this.member_data.city)
      console.log(index)
      cityName_data[index].AreaList.forEach(element => {
        this.townname.push(element.AreaName)
      });
    }
  },
  components: {
    Pagenationtopimg,
    Pagenationtop,
  },
  props: ["closeLogin"],
  created(){
    this.$store.commit('openOverlay')
    this.getCollect()
    this.getOrderList()
    this.getCouponList()
    this.closeLogin();
    this.getCaptcha();
  },
  mounted() {
    if(this.$route.params.id){
      this.activeindex = this.$route.params.id
    }
    if(this.member_data.birthday == '0000-00-00'){
      this.member_data.birthday = '1995-01-01'
    }
    else {
      this.disabled_birthday = true
    }
    this.$store.commit('closeOverlay')
    cityName_data.forEach(element => {
      this.cityname.push(element.CityName)
    });
    let index = this.cityname.indexOf(this.member_data.city)
    if  (this.member_data.city === undefined){
      console.log("city null")
    }
    else{
      cityName_data[index].AreaList.forEach(element => {
        this.townname.push(element.AreaName)
      });
    }

  },
  methods:{
    //修改密碼-取消
    clear(){
      this.edit_password = {
        password_old:null,
        password:null,
        password2:null,
        acode:null,
      }
    },
    //優惠券API
    getCouponList(){
      let vm = this 
      vm.axios.post('/api/member/get_coupon_list', qs.stringify({
        m_id: this.$session.get('memberId'),
      }))
      .then(function(responese)  {
        console.log('----優惠碼')
        console.log(responese),
        vm.get_coupon_list = responese.data.data
        //還沒使用過的往上擺
        if(vm.get_coupon_list != undefined){
          vm.get_coupon_list.forEach(function(element){
            if(element.use_edate != null && element.isuse != 1){
              let element_date = new Date(element.use_edate)
              let new_date = new Date()
              if(element_date < new_date){
                console.log(element_date+'、'+new_date)
                element.isuse = 2
              }
            } 
          }),
          vm.get_coupon_list.sort(function (a,b){
            return a.isuse - b.isuse
          })
        }
      })
    },
    //歷史訂單API
    getOrderList(){
      let vm = this
      this.axios.post('/api/member/get_order_list',qs.stringify({
        m_id:this.$session.get('memberId'),
        isdealer: this.$session.get('isdealer')
      }))
      .then(function(response)  {
        vm.get_order_list = response.data.data
        if(vm.get_order_list != undefined){
          console.log(response),
          vm.get_order_list.reverse()
        }
      })
    },
    getSearchOrderList(ordernumber){
      this.axios.post('/api/member/get_order_list',qs.stringify({
        m_id:this.$session.get('memberId'),
        ordernumber:ordernumber
      }))
      .then(response => (
        this.get_order_list = response.data.data,
        console.log(response)
      ))
    },
    getCaptcha(){
      this.axios.post('/api/member/captcha_show')
      .then(response => (
        this.captcha_img = response.data.message,
        this.info = response.data))
      .catch(function (error){
        console.log(error)
      })
    },
    iconChang(Index){
      if (this.v_text[Index] === 'mdi-plus-circle'){
       this.v_text[Index] = 'mdi-minus-circle'
      }
      else{
        this.v_text[Index] = 'mdi-plus-circle'
      }
    },
    sideTo(index){
      this.activeindex = index
    },
    save (date) {
      this.$refs.menu.save(date)
    },
    /////////修改會員資料api
    editMemberData(){
      let vm = this
      this.axios.post('/api/member/modify', qs.stringify({
        lang: this.$store.state.lang,
        userid: this.$session.get('memberId'),
        name:this.member_data.name,
        sex:this.member_data.sex,
        birthday: this.member_data.birthday,
        tel: this.member_data.tel,
        email: this.member_data.email,
        city: this.member_data.city,
        town: this.member_data.town,
        address: this.member_data.address,
        acode: this.member_data.acode,
        isdealer:this.$session.get('isdealer'),
        phone: this.member_data.mobile_tel
      }))
      .then(function(response){
        vm.info = response,
        console.log(vm.info.data)
        alert(vm.info.data.msg)
        if(response.data.status == true){
            console.log(response)
            vm.$session.set('memberSex',vm.member_data.sex)
            vm.$session.set('memberPhone',vm.member_data.mobile_tel)
            vm.$session.set('memberEmail',vm.member_data.email)
            vm.$session.set('memberBirthday',vm.member_data.birthday)
            vm.$session.set('memberCity',vm.member_data.city)
            vm.$session.set('memberTown',vm.member_data.town)
            vm.$session.set('memberAddress',vm.member_data.address)
            vm.$session.set('memberTel',vm.member_data.tel)
            vm.$session.set('memberName',vm.member_data.name)
            // window.location.reload()
        }
        
      })
      .catch(function(error){
        console.log(error)
      })
    },

    ////////////修改密碼api

    editPassword(){
      this.axios.post('/api/member/passwordchange', qs.stringify({
        lang: this.$store.state.lang,
        userid: this.$session.get('memberId'),
        password_old: this.edit_password.password_old,
        password: this.edit_password.password,
        password2: this.edit_password.password2,
        acode: this.edit_password.acode
      }),
      )
      .then(response => (
        this.info = response,
        alert(this.info.data.msg)
      ))
      .catch(function (error){
        alert(this.info.data.msg)
        console.log(error)
      })
    },
    //收藏API
    getCollect(){
      let vm = this
      vm.axios.post('/api/products/collect_list', qs.stringify({
        lang: vm.$store.state.lang,
        m_id:vm.$session.get('memberId')
      }))
      .then(function(response) {
        console.log(response.data.data),
        vm.collect_list = response.data.data
        
        if(vm.$session.get('isdealer') && 
          !vm.$session.get('memberEmail')){
          alert('請先新增信箱後在執行其他操作，謝謝。')
        }
      })
    },
    //收藏刪除
    deleteCollect(index,collect_id){
      this.$store.commit('openOverlay')
      this.axios.post('/api/products/collect_del',qs.stringify({
        collect_id: collect_id
      }))
      .then(response =>(
        this.$store.commit('closeOverlay'),
        console.log(response),
        this.$delete(this.collect_list,index)
      ))
    },
    //加入購物車
    addShopCar(p_mainid,c_mainid,s_mainid){
        this.axios.post('/api/car/add_car', qs.stringify({
            lang: this.$store.state.lang,
            m_id: this.$session.get('memberId'),
            p_mainid: p_mainid,
            c_mainid:c_mainid,
            s_mainid: s_mainid,
        }))
        .then(response => (
            console.log(response),
            this.$session.set('shopcar',response.data.car),
            this.$store.commit('shopcarItem', response.data.car.item)
        ))
        .catch(err => (
            alert('加入購物車失敗，您沒有選擇顏色或尺寸'+err)
        ))
    },
    //導向產品內頁
    routerProduct(mainid){
        this.$router.push({name:`Productview` , 
        params: {mainid:mainid }})
    },
    goShoppingCenter(){
      this.$router.push({
        name: `ShoppingCenter`,
        params: { fid: "202106220001", sid: "202106220001" },
      });
    },
    goReturnProduct(ordernumber){
      this.$router.push({
        name: `ReturnProduct`,
        params: { ordernumber: ordernumber},
      });
    },

    //密碼顯示status
    eyeStatus(){
        this.password_eye = !this.password_eye
    },

    deleteOrderApi(id){
        let vm = this
        this.axios.post('/api/member/cancel_order', qs.stringify({
          id:id,
          isdealer:this.$session.get('isdealer'),
        }))
        .then(response => (
            console.log(response),
            alert(response.data.msg),
            this.axios.post('/api/member/get_order_list',qs.stringify({
              m_id:this.$session.get('memberId'),
              isdealer: this.$session.get('isdealer')
            }))
            .then(function(response)  {
              vm.get_order_list = response.data.data
              if(vm.get_order_list != undefined){
                console.log(response),
                vm.get_order_list.reverse()
              }
            })
        ))
    }
  }
};
</script>
